import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
} from '@mui/material';
import {
  UploadFile as UploadFileIcon,
} from '@mui/icons-material';
import { format } from 'rut.js';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useLocation, useParams } from 'react-router';
import Page from '../../../components/Layout/Page';
import Header from '../../../components/Layout/Header';
import CircularProgressComponent from '../../../components/Loading/CircularProgressComponent';
import CustomTable from '../../../components/General/CustomTable';
import EmptyTable from '../../../components/General/EmptyTable';
import { INSTITUTIONS_STUDENTS_UI, InstitutionSessionUser } from './types';
import handleApiResponse from '../../../utils/handleApiResponse';
import { bulkUpsertSessionStudents, getInstitutionSessionStudentsPerPage } from '../../../requests/api/institutionUsers';
import UploadExcelModal from './Component/UploadExcelModal';
import { ReduxState } from '../../../types';

interface LocationState {
  name: string,
}

const InstitutionStudentsView = () => {
  const { institutionSessionId } = useParams<{
    institutionId: string, institutionSessionId: string,
  }>();
  const { state } = useLocation<LocationState>();
  const [showLoading, setShowLoading] = useState(true);
  const [allInstitutionStudents, setAllInstitutionStudents] = useState({
    totalItems: 0,
    items: [],
  });
  const [tableParameters, setTableParameters] = useState({
    page: 0,
    rowsPerPage: 5,
  });
  const [modalUploadStudentsInfo, setModalUploadStudentsInfo] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<File | undefined>(undefined);
  const [updateTable, setUpdateTable] = useState(false);
  const { account } = useSelector((storeState: ReduxState) => storeState);

  const userAdmin = Boolean(account.user?.id);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getAllInstitutionSessionStudents = async () => {
      try {
        const {
          institutionSessionStudents, totalInstitutionSessionStudents,
        } = await getInstitutionSessionStudentsPerPage({
          institutionSessionId,
          page: tableParameters.page,
          limit: tableParameters.rowsPerPage,
          sort: 'createdAt|ASC',
        });

        const auxInstitutionSessionStudents = institutionSessionStudents
          .map((elem: InstitutionSessionUser) => ({
            ...elem.institutionUser,
            rut: format(elem.institutionUser.rut),
          }));

        setAllInstitutionStudents({
          items: auxInstitutionSessionStudents, totalItems: totalInstitutionSessionStudents,
        });
        setShowLoading(false);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getAllInstitutionSessionStudents();
  }, [updateTable, tableParameters.page, tableParameters.rowsPerPage]);

  const handleUploadStudentsInfo = () => {
    setModalUploadStudentsInfo(false);
    setFileToUpload(undefined);
  };

  const handleUploadStudents = async () => {
    try {
      const response = await bulkUpsertSessionStudents(
        (fileToUpload as File),
        institutionSessionId,
      );
      handleApiResponse(enqueueSnackbar, response, true);
      handleUploadStudentsInfo();
      setUpdateTable((prev) => !prev);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const getHeaderButtons = () => {
    const buttons = [];
    if (userAdmin) {
      buttons.push({
        text: 'Cargar alumnos',
        icon: <UploadFileIcon />,
        onClick: () => setModalUploadStudentsInfo(true),
      });
    }
    return buttons;
  };

  return (
    <Page
      title="Instituciones | Alumnos"
    >
      <Header
        title={`Alumnos ${state?.name || 'Alumnos'}`}
        buttons={getHeaderButtons()}
        goBack
      />
      {showLoading ? <CircularProgressComponent /> : <Box
        mt={3}
      >
        {allInstitutionStudents.totalItems !== 0
          && <CustomTable
            headers={INSTITUTIONS_STUDENTS_UI}
            data={{
              values: allInstitutionStudents.items,
              count: allInstitutionStudents.totalItems,
            }}
            tableParameters={tableParameters}
            setTableParameters={setTableParameters}
          />}
        {allInstitutionStudents.totalItems === 0 && <EmptyTable />}
      </Box>}
      <UploadExcelModal
        modalUploadStudentsInfo={modalUploadStudentsInfo}
        handleCloseModal={handleUploadStudentsInfo}
        handleUploadStudents={handleUploadStudents}
        setFileToUpload={setFileToUpload}
      />
    </Page>
  );
};

export default InstitutionStudentsView;
