import React, { useCallback, useEffect, useState } from 'react';
import {
  Box, ClassNameMap, IconButton, TextField,
} from '@mui/material';
import {
  AccountBalance as AccountBalanceIcon,
  Add as AddIcon,
  ChevronRight as ChevronRightIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { History } from 'history';
import { useHistory } from 'react-router';
import Header from '../../../components/Layout/Header';
import Page from '../../../components/Layout/Page';
import CircularProgressComponent from '../../../components/Loading/CircularProgressComponent';
import CustomTable from '../../../components/General/CustomTable';
import EmptyTable from '../../../components/General/EmptyTable';
import useStyles from '../styles';
import handleApiResponse from '../../../utils/handleApiResponse';
import { getInstitutionsPerPage, postInstitution, putInstitution } from '../../../requests/api/institutions';
import UploadModal from '../../../components/General/UploadModal';
import { INSTITUTIONS_UI, InstitutionInformation } from '../types';

const editInstitutionAction = (
  classes: ClassNameMap<string>,
  institutionId: string,
  data: { name: string, urlExtension: string, },
  setInstitutionInformation: React.Dispatch<React.SetStateAction<InstitutionInformation>>,
  setInstitutionInformationModal: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>,
) => (<Box>
  <IconButton onClick={() => {
    setInstitutionInformationModal({ open: true, id: institutionId });
    setInstitutionInformation((prevState) => ({
      ...prevState,
      name: data.name,
      urlExtension: data.urlExtension,
    }));
  }}>
    <EditIcon className={classes.icons} />
  </IconButton>
</Box >);

const goToDetailAction = (
  classes: ClassNameMap<string>,
  history: History,
  institutionId: string,
  institutionName: string,
) => (<Box>
  <IconButton onClick={() => history.push({ pathname: `/instituciones/${institutionId}`, state: { institutionName } })}>
    <ChevronRightIcon className={classes.icons} />
  </IconButton>
</Box>);

const ModalInstitutionInformation = ({
  institutionInformation, setInstitutionInformation,
}: {
  institutionInformation: InstitutionInformation,
  setInstitutionInformation: (field: keyof InstitutionInformation, value: string) => void,
}) => (
  <Box>
    <Box mt={2}>
      <TextField
        fullWidth
        label='Nombre Institución'
        variant="outlined"
        value={institutionInformation.name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setInstitutionInformation('name', e.target.value);
        }}
      />
    </Box>
    <Box mt={2} mb={2}>
      <TextField
        fullWidth
        label='URL'
        variant="outlined"
        value={institutionInformation.urlExtension}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setInstitutionInformation('urlExtension', e.target.value);
        }}
      />
    </Box>
  </Box>
);

const InstitutionsListView = () => {
  const [showLoading, setShowLoading] = useState(true);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [allInstitutions, setAllInstitutions] = useState({
    totalItems: 0,
    items: [],
  });
  const [tableParameters, setTableParameters] = useState({
    page: 0,
    rowsPerPage: 5,
  });
  const [updateTable, setUpdateTable] = useState(false);
  const [institutionInformation, setInstitutionInformation] = useState<InstitutionInformation>({ name: '', urlExtension: '', logoFile: undefined });
  const [institutionInformationModal, setInstitutionInformationModal] = useState({ id: '', open: false });
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    const getAllInstitutions = async () => {
      try {
        const { institutions, totalInstitutions } = await getInstitutionsPerPage({ page: tableParameters.page, limit: tableParameters.rowsPerPage, sort: 'createdAt|ASC' });

        const institutionsAndActions = institutions.map((elem: InstitutionInformation) => ({
          ...elem,
          editAction: editInstitutionAction(
            classes,
            elem.id!,
            { name: elem.name, urlExtension: elem.urlExtension },
            setInstitutionInformation,
            setInstitutionInformationModal,
          ),
          detailAction: goToDetailAction(
            classes,
            history,
            elem.id!,
            elem.name,
          ),
        }));
        setAllInstitutions({ items: institutionsAndActions, totalItems: totalInstitutions });
        setShowLoading(false);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getAllInstitutions();
  }, [updateTable]);

  const handleCloseUploadModal = useCallback(() => {
    setInstitutionInformation({ name: '', urlExtension: '', logoFile: undefined });
    setInstitutionInformationModal({ id: '', open: false });
  }, []);

  const handleInputChange = useCallback((field: keyof InstitutionInformation, value: string) => {
    setInstitutionInformation((prevState) => ({ ...prevState, [field]: value }));
  }, []);

  const uploadLogoFunction = useCallback(async () => {
    if (!institutionInformationModal.id && (!institutionInformation.logoFile || institutionInformation.logoFile.type !== 'image/png')) {
      enqueueSnackbar('Debes subir un archivo PNG', {
        variant: 'warning',
      });
      return;
    }

    if (!institutionInformationModal.id
      && (institutionInformation.logoFile as File).size > 200 * 1024) {
      enqueueSnackbar('El archivo no debe ser mayor a 200 kb', {
        variant: 'warning',
      });
      return;
    }

    setUploadLoading(true);
    try {
      let response;
      if (institutionInformationModal.id) {
        response = await putInstitution({
          ...institutionInformation,
          institutionId: institutionInformationModal.id,
        });
      } else {
        response = await postInstitution({ ...institutionInformation });
      }

      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseUploadModal();
      setUpdateTable((prevState) => !prevState);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    } finally {
      setUploadLoading(false);
    }
  }, [
    institutionInformation.name,
    institutionInformation.logoFile,
    institutionInformation.urlExtension,
  ]);

  return (
    <Page
      title="Instituciones"
    >
      <Header
        title='Instituciones'
        icon={<AccountBalanceIcon />}
        buttons={[{ text: 'Crear institución', icon: <AddIcon />, onClick: () => setInstitutionInformationModal({ open: true, id: '' }) }]}
      />
      {showLoading ? <CircularProgressComponent /> : <Box
        mt={3}
      >
        {allInstitutions.totalItems !== 0
          && <CustomTable
            headers={INSTITUTIONS_UI}
            data={{ values: allInstitutions.items, count: allInstitutions.totalItems }}
            tableParameters={tableParameters}
            setTableParameters={setTableParameters}
          />}
        {allInstitutions.totalItems === 0 && <EmptyTable />}
      </Box>}
      <UploadModal
        file={institutionInformation.logoFile}
        handleCloseModal={handleCloseUploadModal}
        handleFileChange={(f: File) => {
          setInstitutionInformation((prevState) => ({ ...prevState, logoFile: f }));
        }}
        header={`${institutionInformationModal.id ? 'Editar' : 'Crear'} institución`}
        loading={uploadLoading}
        modalDelete={institutionInformationModal}
        setModalDelete={setInstitutionInformationModal}
        uploadFunction={uploadLogoFunction}
        warningMessage=''
        uploadButtonText='Subir logo'
      >
        <ModalInstitutionInformation
          institutionInformation={institutionInformation}
          setInstitutionInformation={handleInputChange}
        />
      </UploadModal>
    </Page>
  );
};

export default InstitutionsListView;
