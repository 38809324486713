import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
} from '@mui/material';
import {
  School as SchoolIcon,
  AutoStories as AutoStoriesIcon,
  FamilyRestroom as FamilyRestroomIcon,
  SupervisorAccount as SupervisorAccountIcon,
  LocalLibrary as LocalLibraryIcon,
  Article as ArticleIcon,
  Portrait as PortraitIcon,
  CrisisAlert as CrisisAlertIcon,
  FileOpen as FileOpenIcon,
  Download as DownloadIcon,
  AccountBalance as AccountBalanceIcon,
} from '@mui/icons-material/';
import { useSelector } from 'react-redux';
import Logo from '../../Layout/Logo';
import NavItem from './NavItem';
import { NavigationItemProps, ReduxState } from '../../../types';

import useStyles from './styles';
import {
  ACADEMIC, ADMINISTRATOR, SECRETARY, TEACHER,
} from '../../../utils/user_types';

const {
  NODE_ENV = 'development',
} = process.env;

interface RenderNavItemsProps {
  items: NavigationItemProps[];
  pathname: string;
}

function renderNavItems(
  { items, pathname }: RenderNavItemsProps,
  role: string,
): JSX.Element {
  return (
    <List disablePadding>
      {items?.reduce(
        (acc: JSX.Element[], item) => reduceChildRoutes({ acc, item, pathname }, role),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc, pathname, item, depth = 0,
}: {
  acc: JSX.Element[], pathname: string, item: NavigationItemProps, depth?: number,
}, role: string) {
  const key = `${item.title || ''}depth`;

  if (!item.roles.includes(role)) return [...acc];

  if (item.items && item.items.length > 0) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          items: item.items,
          pathname,
        }, role)}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />,
    );
  }

  return acc;
}

function NavBar({ openMobile, onMobileClose }: {
  openMobile: boolean, onMobileClose: () => void
}) {
  const classes = useStyles();
  const location = useLocation();
  const account = useSelector((state: ReduxState) => state.account);
  const navConfig = [
    {
      subheader: 'Preu',
      items: [
        {
          title: 'Alumnos',
          icon: LocalLibraryIcon,
          href: '/alumnos',
          roles: [ADMINISTRATOR, ACADEMIC, SECRETARY],
        },
        {
          title: 'Programas',
          icon: SchoolIcon,
          href: '/programas',
          roles: [ADMINISTRATOR, ACADEMIC, SECRETARY],
        },
        {
          title: 'Secciones',
          icon: AutoStoriesIcon,
          href: '/secciones',
          roles: [ADMINISTRATOR, TEACHER, ACADEMIC, SECRETARY],
        },
        {
          title: 'Apoderados',
          icon: FamilyRestroomIcon,
          href: '/apoderados',
          roles: [ADMINISTRATOR, ACADEMIC, SECRETARY],
        },
        {
          title: 'Profesores',
          icon: SupervisorAccountIcon,
          href: '/profesores',
          roles: [ADMINISTRATOR, ACADEMIC, SECRETARY],
        },
        {
          title: 'Guías',
          icon: ArticleIcon,
          href: '/guias',
          roles: [ADMINISTRATOR, ACADEMIC, SECRETARY],
        },
        {
          title: 'Asistencias',
          icon: PortraitIcon,
          href: '/clases',
          roles: [ADMINISTRATOR, TEACHER, ACADEMIC, SECRETARY],
        },
        {
          title: 'Masivos',
          icon: FileOpenIcon,
          href: '/masivos',
          roles: [ADMINISTRATOR, ACADEMIC, SECRETARY],
        },
        {
          title: 'Bombas',
          icon: CrisisAlertIcon,
          href: '/bombas',
          roles: [ADMINISTRATOR, ACADEMIC, SECRETARY],
        },
        {
          title: 'Descargas',
          icon: DownloadIcon,
          href: '/descargas',
          roles: [ADMINISTRATOR, ACADEMIC, SECRETARY],
        },
      ],
    },
  ];

  if (NODE_ENV === 'development' || NODE_ENV === 'staging') {
    navConfig.push({
      subheader: 'Externo',
      items: [
        {
          title: 'Instituciones',
          icon: AccountBalanceIcon,
          href: '/instituciones',
          roles: [ADMINISTRATOR],
        },
      ],
    });
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column" className={classes.navBarContainer}>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo className={classes.logo} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          {navConfig.map((config, index) => (
            <List
              key={index}
              subheader={(account.user && account.user.userType) === ADMINISTRATOR ? (
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              ) : undefined}
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname,
              }, (account.user && account.user.userType) ?? '')}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return <>
    <Hidden lgUp>
      <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        {content}
      </Drawer>
    </Hidden>
    <Hidden lgDown>
      <Drawer
        anchor="left"
        classes={{ paper: classes.desktopDrawer }}
        open
        variant="persistent"
      >
        {content}
      </Drawer>
    </Hidden>
  </>;
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
