import {
  Box,
  Card,
  CardContent,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import React from 'react';
import { Close as CloseIcon, Check as CheckIcon } from '@mui/icons-material';
import useStyles from '../../styles';
import CustomButton from '../../../../components/General/CustomButton';

const UpsertConfirmationModal = ({
  upsertConfirmationModal,
  setUpsertConfirmationModal,
  upsertInstitutionFunction,
}: {
  upsertConfirmationModal: boolean,
  setUpsertConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>,
  upsertInstitutionFunction: () => void,
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={upsertConfirmationModal}
      onClose={() => setUpsertConfirmationModal(false)}
    >
      <Box className={classes.modalContainer}>
        <Card className={classes.modal}>
          <CardContent className={classes.modalContent}>
            <Box className={classes.modalTitleContainer}>
              <Box className={classes.modalTitleAndIcon}>
                <Typography variant='h4' color='primary' fontWeight='bold'>Sesion Activa</Typography>
              </Box>
              <IconButton onClick={() => setUpsertConfirmationModal(false)}>
                <CloseIcon color='primary' />
              </IconButton>
            </Box>
            <Box className={classes.modalTextfieldsContainer}>
              <Typography variant='body1' color='primary' fontWeight='bold'>Al momento de crear o editar una sesion y dejarla como activa, la otra sesion pasara inactiva. Si estas de acuerdo con esto, presioina confirmar.</Typography>
            </Box>
            <Box className={classes.modalButtonsContainer}>
              <CustomButton text='Cancelar' colorType='tertiary' onClick={() => setUpsertConfirmationModal(false)} key={'CancelButtonUpsertInstitutionSession'} icon={<CloseIcon />} />
              <CustomButton text='Confirmar' colorType='primary' onClick={() => { upsertInstitutionFunction(); setUpsertConfirmationModal(false); }} key={'AcceptButtonUpdertInstitutionSession'} icon={<CheckIcon />} />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

export default UpsertConfirmationModal;
