import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Edit as EditIcon,
  Quiz as QuizIcon,
  Upload as UploadIcon,
} from '@mui/icons-material';

import {
  Box,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { useParams } from 'react-router';
import SyncIcon from '@mui/icons-material/Sync';
import Header from '../../../components/Layout/Header';
import Page from '../../../components/Layout/Page';
import CustomTable from '../../../components/General/CustomTable';
import handleApiResponse from '../../../utils/handleApiResponse';
import EmptyTable from '../../../components/General/EmptyTable';

import { Question, QuestionsData, TEST_QUESTIONS_UI } from './types';
import {
  getTestQuestions, postUploadQuestionImages, postUploadSolutionary, postUploadsolutionImages,
  putEditTest,
  putTest,
  postUploadOptimarkAnswers,
  checkExcelFileToUpload,
  postUploadTestPDF,
} from '../../../requests/api/test';
import UploadModal from '../../../components/General/UploadModal';
import InfoDisplay from '../../../components/General/InfoDisplay';
import { validZipMimeTypes } from '../../../utils/zip';
import UpsertModal from '../../../components/General/UpsertModal';
import { validMSExcelMimeTypes } from '../../../utils/excel';
import ConfirmChangesModal from './Modals/ConfirmChangesModal';

function TestQuestionsView() {
  const { id } = useParams<{ id: string }>();
  const [files, setFiles] = useState<{
    questionsFile: File | undefined,
    solutionsFile: File | undefined,
    workoutFile: File | undefined,
    editInfoFile: File | undefined,
    testFile: File | undefined,
  }>({
    questionsFile: undefined,
    solutionsFile: undefined,
    workoutFile: undefined,
    editInfoFile: undefined,
    testFile: undefined,
  });

  const [modalUploadQuestions, setModalUploadQuestions] = useState({ open: false, id: '' });
  const [modalUploadSolutions, setModalUploadSolutions] = useState({ open: false, id: '' });
  const [modalUploadWorkout, setModalUploadWorkout] = useState({ open: false, id: '' });
  const [modalUploadTest, setModalUploadTest] = useState({ open: false, id: '' });
  const [modalEditInformation, setModalEditInformation] = useState({ open: false, id: '' });
  const [modalConfirmEditInformation, setModalConfirmEditInformation] = useState<{
    questions: Question[], numberOfDiffs: number, open: boolean,
  }>({
    open: false,
    numberOfDiffs: 0,
    questions: [],
  });
  const [modalEdit, setModalEdit] = useState({ open: false, id: 'edit' });
  const [update, setUpdate] = useState(false);
  const [selectedTest, setSelectedTest] = useState({
    name: '',
    type: '',
    optimarkId: '',
    subject: {
      name: ',',
    },
    numberOfQuestions: 0,
  });
  const [editValues, setEditValues] = useState({ name: selectedTest.name });
  const [allQuestions, setAllQuestions] = useState({
    items: [],
    totalItems: 0,
  });
  const [tableParameters, setTableParameters] = useState({
    page: 0,
    rowsPerPage: 5,
  });
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getAllQuestions = async () => {
      try {
        const { questions, totalQuestions, test } = await getTestQuestions({
          page: tableParameters.page, limit: tableParameters.rowsPerPage, sort: 'questionNumber|ASC', testId: id,
        });

        const parsedQuestions = questions.map((q: QuestionsData) => ({
          ...q,
          category: q.question.category,
          topic: q.question.topic,
          difficulty: q.question.difficulty,
          correctAnswer: q.question.correctAnswer,
          questionImageUrl: q.question.questionImageUrl ? 'OK' : '-',
          solutionImageUrl: q.question.solutionImageUrl ? 'OK' : '-',
        }));

        setSelectedTest(test);
        setEditValues({ name: test.name });
        setAllQuestions({ items: parsedQuestions, totalItems: totalQuestions });
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getAllQuestions();
  }, [tableParameters.page, tableParameters.rowsPerPage, update]);

  const uploadOptimarkAnswers = async ({ optimarkTestId }: { optimarkTestId: string | number }) => {
    setLoading(true);
    try {
      const response = await postUploadOptimarkAnswers(optimarkTestId);
      handleApiResponse(enqueueSnackbar, response, true);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
    setLoading(false);
  };
  ///

  const handleCloseModal = (setFunction: React.Dispatch<React.SetStateAction<{
    open: boolean, id: string;
  }>>) => {
    setFunction(() => ({ open: false, id: '' }));
    setFiles({
      solutionsFile: undefined,
      questionsFile: undefined,
      workoutFile: undefined,
      editInfoFile: undefined,
      testFile: undefined,
    });
  };

  const changeEditValues = (
    e: File | Date | React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event>,
  ) => {
    if (!(e instanceof File || e instanceof Date) && e.target instanceof HTMLInputElement) {
      setEditValues({ name: e.target.value });
    }
  };

  const uploadQuestions = async () => {
    if (!selectedTest
      || !files.questionsFile || !validZipMimeTypes.includes(files.questionsFile.type)) {
      enqueueSnackbar('Debes subir un archivo .zip', {
        variant: 'warning',
      });
      return;
    }

    try {
      setLoading(true);
      const response = await postUploadQuestionImages(files.questionsFile, id);
      setLoading(false);
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal(setModalUploadQuestions);
      setUpdate(!update);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
      setLoading(false);
    }
  };

  const uploadSolutions = async () => {
    if (!selectedTest
      || !files.solutionsFile || !validZipMimeTypes.includes(files.solutionsFile.type)) {
      enqueueSnackbar('Debes subir un archivo .zip', {
        variant: 'warning',
      });
      return;
    }

    try {
      setLoading(true);
      const response = await postUploadsolutionImages(files.solutionsFile, id);
      setLoading(false);
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal(setModalUploadSolutions);
      setUpdate(!update);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
      setLoading(false);
    }
  };

  const uploadWorkout = async () => {
    if (!selectedTest || !files.workoutFile || files.workoutFile.type !== 'application/pdf') {
      enqueueSnackbar('Debes subir un archivo PDF', {
        variant: 'warning',
      });
      return;
    }

    try {
      setLoading(true);
      const response = await postUploadSolutionary(files.workoutFile, id);
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal(setModalUploadWorkout);
      setLoading(false);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
      setLoading(false);
    }
  };

  const uploadTest = async () => {
    if (!selectedTest || !files.testFile || files.testFile.type !== 'application/pdf') {
      enqueueSnackbar('Debes subir un archivo PDF', {
        variant: 'warning',
      });
      return;
    }

    try {
      setLoading(true);
      const response = await postUploadTestPDF(files.testFile, id);
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal(setModalUploadTest);
      setLoading(false);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
      setLoading(false);
    }
  };

  const editName = async () => {
    try {
      setLoading(true);
      const response = await putEditTest(editValues, id);
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal(setModalEdit);
      setSelectedTest({ ...selectedTest, name: editValues.name });
      setLoading(false);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
      setLoading(false);
    }
  };

  const checkEditedInformation = async () => {
    if (!selectedTest
      || !files.editInfoFile || !validMSExcelMimeTypes.includes(files.editInfoFile.type)) {
      enqueueSnackbar('Debes subir un archivo Excel', {
        variant: 'warning',
      });
      return;
    }

    try {
      setLoading(true);
      const response = await checkExcelFileToUpload(files.editInfoFile, id, `${selectedTest.numberOfQuestions}`);
      setLoading(false);

      if (response.numberOfDiffs === 0) {
        handleApiResponse(enqueueSnackbar, response, false);
        handleCloseModal(setModalEditInformation);
      } else {
        setModalConfirmEditInformation({
          open: true,
          numberOfDiffs: response.numberOfDiffs,
          questions: response.questions,
        });
      }
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
      setLoading(false);
    }
  };

  const uploadEditedInfo = async () => {
    try {
      setLoading(true);
      const response = await putTest(
        modalConfirmEditInformation.questions,
        id,
      );
      setLoading(false);
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal(setModalEditInformation);
      handleCloseEditFunction();
      setUpdate(!update);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
      setLoading(false);
    }
  };

  const handleCloseEditFunction = () => {
    setModalConfirmEditInformation({
      open: false,
      numberOfDiffs: 0,
      questions: [],
    });
  };

  return (
    <Page
      title={selectedTest.name}
    >
      <Header
        title={`Configurar "${selectedTest.name}"`}
        icon={<QuizIcon />}
        buttons={[
          { text: 'Subir .zip de preguntas', icon: <UploadIcon />, onClick: () => setModalUploadQuestions({ open: true, id: '' }) },
          { text: 'Subir .zip de soluciones', icon: <UploadIcon />, onClick: () => setModalUploadSolutions({ open: true, id: '' }) },
          { text: 'Subir .pdf de solucionario', icon: <UploadIcon />, onClick: () => setModalUploadWorkout({ open: true, id: '' }) },
          { text: 'Subir .pdf de ensayo', icon: <UploadIcon />, onClick: () => setModalUploadTest({ open: true, id: '' }) },
          { text: 'Cambiar nombre', icon: <EditIcon />, onClick: () => setModalEdit({ open: true, id: 'edit' }) },
          { text: 'Cambiar información', icon: <EditIcon />, onClick: () => setModalEditInformation({ open: true, id: '' }) },
          { text: 'Sincronizar guía', icon: <SyncIcon />, onClick: () => uploadOptimarkAnswers({ optimarkTestId: selectedTest.optimarkId }) },
        ]}
        backUrl='/guias'
      />
      <InfoDisplay info={{
        Tipo: selectedTest.type,
        'ID Optimark': selectedTest.optimarkId,
        Asignatura: selectedTest.subject.name,
        'Número de preguntas': selectedTest.numberOfQuestions,
      }} />
      <Box>

      </Box>
      <Box
        mt={3}
      >
        {allQuestions.totalItems !== 0
          && <CustomTable
            headers={TEST_QUESTIONS_UI.filter((elem: { showOnTable: boolean }) => elem.showOnTable)}
            data={{ values: allQuestions.items, count: allQuestions.totalItems }}
            tableParameters={tableParameters}
            setTableParameters={setTableParameters}
          />}
        {allQuestions.totalItems === 0 && <EmptyTable />}
      </Box>
      {/* Questions upload modal */}
      <UploadModal
        header='Subir .zip de preguntas'
        warningMessage='Si hay imágenes anteriores, estas serán reemplazadas'
        handleCloseModal={handleCloseModal}
        modalDelete={modalUploadQuestions}
        setModalDelete={setModalUploadQuestions}
        file={files.questionsFile}
        handleFileChange={(f: File) => { setFiles({ ...files, questionsFile: f }); }}
        uploadFunction={uploadQuestions}
        loading={loading}
      />
      <UploadModal
        header='Subir .zip de soluciones'
        warningMessage='Si hay imágenes anteriores, estas serán reemplazadas'
        handleCloseModal={handleCloseModal}
        modalDelete={modalUploadSolutions}
        setModalDelete={setModalUploadSolutions}
        file={files.solutionsFile}
        handleFileChange={(f: File) => { setFiles({ ...files, solutionsFile: f }); }}
        uploadFunction={uploadSolutions}
        loading={loading}
      />
      <UploadModal
        header='Subir .pdf de solucionario'
        warningMessage='Si hay un PDF cargado, este será reemplazado'
        handleCloseModal={handleCloseModal}
        modalDelete={modalUploadWorkout}
        setModalDelete={setModalUploadWorkout}
        file={files.workoutFile}
        handleFileChange={(f: File) => { setFiles({ ...files, workoutFile: f }); }}
        uploadFunction={uploadWorkout}
        loading={loading}
      />
      <UploadModal
        header='Subir .pdf de ensayo'
        warningMessage='Si hay un PDF cargado, este será reemplazado'
        handleCloseModal={handleCloseModal}
        modalDelete={modalUploadTest}
        setModalDelete={setModalUploadTest}
        file={files.testFile}
        handleFileChange={(f: File) => { setFiles({ ...files, testFile: f }); }}
        uploadFunction={uploadTest}
        loading={loading}
      />
      <UploadModal
        header='Subir nuevo excel con información para Guía'
        warningMessage='Toda la información entregada sera actualizada en la guía'
        handleCloseModal={handleCloseModal}
        modalDelete={modalEditInformation}
        setModalDelete={setModalEditInformation}
        file={files.editInfoFile}
        handleFileChange={(f: File) => { setFiles({ ...files, editInfoFile: f }); }}
        uploadFunction={checkEditedInformation}
        loading={loading}
      />
      <UpsertModal
        title="nombre"
        modalAddEdit={modalEdit}
        setModalAddEdit={setModalEdit}
        handleCloseModal={handleCloseModal}
        modalValues={editValues}
        handleFieldChange={changeEditValues}
        itemsUI={[{
          label: 'Nombre', key: 'name', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
        }]}
        handleAddFunction={editName}
        handleEditFunction={editName}
      />
      <ConfirmChangesModal
        handleEditFunction={uploadEditedInfo}
        handleCloseModal={handleCloseEditFunction}
        modalConfirmEditInformation={modalConfirmEditInformation}
      />
    </Page>
  );
}

export default TestQuestionsView;
