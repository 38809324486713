import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Table,
  TableContainer,
  Paper,
  Checkbox,
} from '@mui/material';
import {
  Check as CheckIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import axios, { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router';
import Page from '../../../components/Layout/Page';
import useStyles from '../styles';
import handleApiResponse from '../../../utils/handleApiResponse';
import { getQuestionsForTest, postSessionTestAnswers } from '../../../requests/api/institutionTests';
import CircularProgressComponent from '../../../components/Loading/CircularProgressComponent';
import CustomButton from '../../../components/General/CustomButton';
import HeaderInformation from './Component/HeaderInformation';
import LocalTestInformation from '../../../services/institutionUserTestService';
import ErrorScreen from '../../../components/states/ErrorScreen';

const numberToAnswerMap = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
  4: 'E',
};

const answerToNumberMap = {
  A: 0,
  B: 1,
  C: 2,
  D: 3,
  E: 4,
};

type ValidUserAnswerKeys = 'A' | 'B' | 'C' | 'D' | 'E';

const paddingBottom = 24;
const extraPaddingBottom = 50;

const InstitutionUserTestAttempt = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { institutionSessionUserTestId, institutionUrlExtension } = useParams<{
    institutionSessionUserTestId: string,
    institutionUrlExtension: string,
  }>();
  const [testInformation, setTestInformation] = useState({
    testName: '',
    durationMinutes: 0,
    subjectName: '',
    testQuestions: {} as { [key: string]: string },
  });
  const history = useHistory();
  const [testLoading, setTestLoading] = useState(true);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [selectedAnswer, setSelectedAnswer] = useState(Array(5).fill(false));
  const [userAnswers, setUserAnswers] = useState<{
    [key: string]: { answer: string | undefined, markedForRevision: boolean },
  }>({});
  const [error, setError] = useState({ showError: false, message: 'Contactar administrador' });
  const [uiMeasurements, setUIMeasurements] = useState({
    totalHeight: 0,
    totalWidth: 0,
    distanceElementsToTop: 0,
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getInitialLoadingInformation = async () => {
      try {
        const { questionsForTest } = (
          await getQuestionsForTest(institutionSessionUserTestId));

        if (Object.values(questionsForTest.testQuestions)
          .some((elem) => elem === null)) {
          throw new Error('Error al mostrar preguntas del examen. Contactar administrador.');
        }

        let auxUserAnswers = LocalTestInformation.getUserTestAnswers();

        if (!auxUserAnswers) {
          auxUserAnswers = Array(Object.keys(questionsForTest.testQuestions).length).fill(0)
            .reduce((acc, val, index) => {
              acc[index + 1] = null;
              return acc;
            }, {});
        }

        const testInProgress = LocalTestInformation.getTestInProgress();

        if (!testInProgress) {
          LocalTestInformation.setTestInProgress({
            institutionSessionUserTestId, institutionUrlExtension,
          });
        }

        setTestInformation(questionsForTest);
        setUserAnswers(auxUserAnswers);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
        setError({ showError: true, message: e.response?.data.message || 'Contactar administrador' });
      }

      setTestLoading(false);
    };

    getInitialLoadingInformation();
    return () => {
      source.cancel();
    };
  }, []);

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      const distanceElementsToTop = node
        .getBoundingClientRect().top;
      setUIMeasurements((prevState) => ({
        ...prevState,
        distanceElementsToTop: Math.floor(distanceElementsToTop),
      }));
    }

    setUIMeasurements((prevState) => ({
      ...prevState,
      totalHeight: (window as Window).innerHeight,
      totalWidth: (window as Window).innerWidth,
    }));
  }, []);

  const handleAnswerChange = (selection: number) => {
    const auxSelectedAnswer = Array(5).fill(false);
    if (!selectedAnswer[selection]) {
      auxSelectedAnswer[selection] = true;
    }
    setSelectedAnswer(auxSelectedAnswer);

    const answerFromUser = auxSelectedAnswer.indexOf(true);
    const formattedAnswer = answerFromUser === -1
      ? null
      : numberToAnswerMap[answerFromUser as 0 | 1 | 2 | 3 | 4];
    setUserAnswers((prevState) => (
      {
        ...prevState,
        [currentQuestion]: {
          ...prevState[currentQuestion], answer: formattedAnswer,
        },
      }
    ));
    LocalTestInformation.setUserTestAnswers({
      ...userAnswers,
      [currentQuestion]: { ...userAnswers[currentQuestion], answer: formattedAnswer },
    });
  };

  const showSelectedCheckboxAnswerByUser = (relevantQuestion: number) => {
    const auxSelectedAnswer = userAnswers[(relevantQuestion).toString()]
      ?.answer as ValidUserAnswerKeys;

    const mappedSelectedAnswer = auxSelectedAnswer ? answerToNumberMap[auxSelectedAnswer] : null;
    if (mappedSelectedAnswer !== null) {
      const answersArray = Array(5).fill(false);
      answersArray[mappedSelectedAnswer] = true;
      setSelectedAnswer(answersArray);
    } else setSelectedAnswer(Array(5).fill(false));
  };

  const setSpecificClassTableAnswer = (numberQuestion: string) => {
    if (!userAnswers[numberQuestion]?.answer) {
      return classes.tableRowAnswerNotAnswered;
    }
    return '';
  };

  const setSpecificIconTableAnswer = (
    answer: { answer: string | undefined, markedForRevision: boolean },
  ) => {
    if (answer?.answer && !answer.markedForRevision) {
      return <CheckIcon />;
    }
    if (answer?.markedForRevision) {
      return <WarningIcon />;
    }
    return '';
  };

  const setQuestionForRevision = () => {
    setUserAnswers((prevState) => (
      {
        ...prevState,
        [currentQuestion]: { ...prevState[currentQuestion], markedForRevision: true },
      }));
    LocalTestInformation.setUserTestAnswers(
      {
        ...userAnswers,
        [currentQuestion]: { ...userAnswers[currentQuestion], markedForRevision: true },
      },
    );
  };

  const handlePrevNextQuestion = (source: string) => {
    const direction = source === 'n' ? 1 : -1;
    setCurrentQuestion((prevState) => prevState + direction);
    showSelectedCheckboxAnswerByUser(currentQuestion + direction);
  };

  const handleDeliverTest = async () => {
    try {
      const response = await postSessionTestAnswers(institutionSessionUserTestId, userAnswers);
      if (response.success) {
        LocalTestInformation.resetInstitutionUserTestInfo();
        handleApiResponse(enqueueSnackbar, response, true);
        history.replace(`/institucion/${institutionUrlExtension}/ensayos`);
      }
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const handleClickQuestion = (questionToChoose: number) => {
    if (questionToChoose !== currentQuestion) {
      setCurrentQuestion(questionToChoose);
      showSelectedCheckboxAnswerByUser(questionToChoose);
    }
  };

  const getCompetence = () => (testInformation.subjectName === 'MATEMATICA'
    ? 'Competencia Matemática'
    : 'Competencia Lectora');

  return (
    <Page
      title='En prueba...'
    >
      {testLoading && <CircularProgressComponent />}
      {!testLoading && error.showError && <ErrorScreen error={error.message} />}
      {!testLoading && !error.showError && <Grid container spacing={2} display='flex'>
        <Grid item xs={3}>
          <Typography
            ml={1}
            variant="h3"
            fontWeight='bold'
            display='flex'
            alignItems='center'
            color='primary'
          >
            {testInformation?.testName ? `${testInformation?.testName}  - ${getCompetence()}` : 'Nombre ensayo no definido'}
          </Typography>
        </Grid>
        <HeaderInformation
          userAnswers={userAnswers}
          handleDeliverTest={handleDeliverTest}
          durationMinutes={testInformation.durationMinutes}
        />
        <Grid item xs={3}>
          <Paper className={classes.tablePaperContainer} ref={measuredRef}>
            <TableContainer sx={{
              maxHeight: uiMeasurements.totalHeight
                - uiMeasurements.distanceElementsToTop
                - paddingBottom - extraPaddingBottom,
            }} >
              <Table style={{ tableLayout: 'fixed' }} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCellPrimaryBackgroundColor}>
                      Número de pregunta
                    </TableCell>
                    <TableCell className={classes.tableCellPrimaryBackgroundColor}>
                      Respuesta
                    </TableCell>
                    <TableCell className={classes.tableCellPrimaryBackgroundColor}>
                      Por revisar
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(userAnswers).map((elem) => (
                    <TableRow
                      key={elem[0]}
                      onClick={() => handleClickQuestion(parseInt(elem[0], 10))}
                      className={`${classes.tableRowAnswerBase} ${setSpecificClassTableAnswer(elem[0])}`}
                    >
                      <TableCell>{elem[0]}</TableCell>
                      <TableCell>{elem[1]?.answer ? elem[1].answer : '-'}</TableCell>
                      <TableCell>{setSpecificIconTableAnswer(elem[1])}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={9} display='flex' flexDirection='column'>
          <Box display='flex' flex={1}>
            <img src={testInformation.testQuestions[currentQuestion] as string} width='100%' />
          </Box>
          {/* sx={{ marginTop: '30px' }} */}
          <TableContainer component={Paper} >
            <Table style={{ tableLayout: 'fixed' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Número de pregunta</TableCell>
                  <TableCell>A</TableCell>
                  <TableCell>B</TableCell>
                  <TableCell>C</TableCell>
                  <TableCell>D</TableCell>
                  <TableCell>E</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{currentQuestion}</TableCell>
                  <TableCell>
                    <Checkbox checked={selectedAnswer[0]} onChange={() => handleAnswerChange(0)} />
                  </TableCell>
                  <TableCell>
                    <Checkbox checked={selectedAnswer[1]} onChange={() => handleAnswerChange(1)} />
                  </TableCell>
                  <TableCell>
                    <Checkbox checked={selectedAnswer[2]} onChange={() => handleAnswerChange(2)} />
                  </TableCell>
                  <TableCell>
                    <Checkbox checked={selectedAnswer[3]} onChange={() => handleAnswerChange(3)} />
                  </TableCell>
                  <TableCell>
                    <Checkbox checked={selectedAnswer[4]} onChange={() => handleAnswerChange(4)} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {/*  sx={{ margin: `30px 0px ${extraPaddingBottom}px 0px` }} */}
          <Box display='flex' justifyContent='space-between'>
            <CustomButton text={'Marcar pregunta para revisión'} colorType='warning' onClick={setQuestionForRevision} icon={<WarningIcon />} />
            <Box display='flex'>
              <CustomButton text={'Anterior'} disabled={currentQuestion === 1} colorType='primary' onClick={() => handlePrevNextQuestion('p')} icon={<ChevronLeftIcon />} />
              <CustomButton text={'Siguiente'} disabled={currentQuestion === Object.values(userAnswers).length} colorType='primary' onClick={() => handlePrevNextQuestion('n')} suffixIcon={<ChevronRightIcon />} />
            </Box>
          </Box>
        </Grid>
      </Grid>}

    </Page>
  );
};

export default InstitutionUserTestAttempt;
