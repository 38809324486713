import { Box } from '@mui/material';
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { AverageTestScoreBySubject } from '../types';

export const ColumnBarChart = ({ averageTestScoreBySubject }: {
  averageTestScoreBySubject: AverageTestScoreBySubject,
}) => {
  const tripleColumnChartOptions = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Promedio ensayos',
      align: 'left',
    },
    xAxis: {
      categories: ['Matemáticas', 'Lenguaje'],
    },
    tooltip: {
      valueSuffix: '%',
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Puntaje Promedio (%)',
      },
    },
    series: [
      {
        name: 'Presencial',
        data: [
          Math.round(averageTestScoreBySubject?.MATEMATICA.inPerson * 10000) / 100 || 0,
          Math.round(averageTestScoreBySubject?.LENGUAJE.inPerson * 10000) / 100 || 0,
        ],
      },
      {
        name: 'Online',
        data: [
          Math.round(averageTestScoreBySubject?.MATEMATICA.online * 10000) / 100 || 0,
          Math.round(averageTestScoreBySubject?.LENGUAJE.online * 10000) / 100 || 0,
        ],
      },
      {
        name: 'General',
        data: [
          Math.round(averageTestScoreBySubject?.MATEMATICA.general * 10000) / 100 || 0,
          Math.round(averageTestScoreBySubject?.LENGUAJE.general * 10000) / 100 || 0,
        ],
      },
    ],
  };
  return (
    <Box style={{ width: '33%' }}>
      <HighchartsReact highcharts={Highcharts} options={tripleColumnChartOptions} />
    </Box>
  );
};
