import axios from '../../utils/axios';
import { InstitutionInformation } from '../../views/Institutions/types';

const getInstitutionsPerPage = async (
  { page, limit, sort }: { page: number, limit: number, sort: string },
) => {
  const response = await axios.get(`/api/institutions?page=${page}&limit=${limit}&sort=${sort}`);

  return response.data;
};

const postInstitution = async ({ name, urlExtension, logoFile }: InstitutionInformation) => {
  const form = new FormData();

  if (logoFile) {
    form.append('file', logoFile);
    form.append('json', JSON.stringify({ name, urlExtension }));
  }
  const response = await axios.post('/api/institutions', form);

  return response.data;
};

const putInstitution = async ({
  name, urlExtension, logoFile, institutionId,
}: InstitutionInformation & { institutionId: string }) => {
  const form = new FormData();

  if (logoFile) {
    form.append('file', logoFile);
  }
  form.append('json', JSON.stringify({ name, urlExtension, institutionId }));
  const response = await axios.put('/api/institutions', form);

  return response.data;
};

const getInstitutionSessionsPerPage = async (
  {
    institutionId, page, limit, sort,
  }: {
    institutionId: string, page: number, limit: number, sort: string,
  },
) => {
  const response = await axios.get(`/api/institution-sessions/${institutionId}?page=${page}&limit=${limit}&sort=${sort}`);

  return response.data;
};

const putActiveInstitutionSessions = async (
  institutionId: string,
  institutionSessionId: number,
) => {
  const response = await axios.put('/api/institution-sessions/activate-session', { institutionId, institutionSessionId });

  return response.data;
};

const getTests = async () => {
  const response = await axios.get('/api/institution-sessions/all-tests');
  return response.data;
};

const postInstitutionSession = async ({
  name,
  startDate,
  endDate,
  resultsDate,
  active,
  institutionSessionTests,
  institutionId,
}: {
  name: string,
  startDate: Date,
  endDate: Date,
  resultsDate: Date,
  active: boolean,
  institutionSessionTests: number[],
  institutionId: string,
}) => {
  const response = await axios.post('/api/institution-sessions', {
    name,
    startDate,
    endDate,
    resultsDate,
    active,
    institutionSessionTests,
    institutionId,
  });

  return response.data;
};

const putInstitutionSession = async ({
  name,
  startDate,
  endDate,
  resultsDate,
  active,
  institutionSessionTests,
  institutionId,
  institutionSessionId,
}: {
  name: string,
  startDate: Date,
  endDate: Date,
  resultsDate: Date,
  active: boolean,
  institutionSessionTests: number[],
  institutionId: string,
  institutionSessionId: string,
}) => {
  const response = await axios.put(`/api/institution-sessions/${institutionSessionId}`, {
    name,
    startDate,
    endDate,
    resultsDate,
    active,
    institutionSessionTests,
    institutionId,
  });

  return response.data;
};

const deleteInstitutionSession = async (institutionSessionId: string) => {
  const response = await axios.delete(`/api/institution-sessions/${institutionSessionId}`);

  return response.data;
};

const getInstitutionInfoByUrlExtension = async (
  { institutionUrlExtension }: { institutionUrlExtension: string },
) => {
  const response = await axios.get(`/api/institution/${institutionUrlExtension}`);

  return response.data;
};

export {
  getInstitutionsPerPage,
  getInstitutionSessionsPerPage,
  postInstitution,
  putInstitution,
  putActiveInstitutionSessions,
  getTests,
  postInstitutionSession,
  putInstitutionSession,
  deleteInstitutionSession,
  getInstitutionInfoByUrlExtension,
};
