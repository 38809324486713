import React from 'react';
import {
  Button, CircularProgress, Theme, alpha,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

// Define the types for the props
interface CustomButtonProps {
  icon?: JSX.Element;
  text: string;
  colorType: 'primary' | 'secondary' | 'tertiary' | 'warning';
  onClick: VoidFunction;
  disabled?: boolean;
  loading?: boolean;
  suffixIcon?: JSX.Element;
}

// Use makeStyles to define styles based on the theme
const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {
    margin: theme.spacing(1),
    color: theme.palette.common.white,
    fontWeight: 'bold',
    textTransform: 'none',
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  tertiary: {
    backgroundColor: theme.palette.primary.button,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.button!, 0.85),
    },
  },
  warning: {
    backgroundColor: '#F2AC32',
    '&:hover': {
      backgroundColor: alpha('#F2AC32', 0.85),
    },
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
}));

const CustomButton: React.FC<CustomButtonProps> = ({
  icon, text, colorType, onClick, disabled, loading, suffixIcon,
}) => {
  const classes = useStyles();

  const buttonClass = classes[colorType];

  return (
    <Button
      disabled={disabled || loading || false}
      variant="contained"
      className={`${classes.button} ${buttonClass}`}
      startIcon={!loading ? (icon || null) : null}
      endIcon={!loading ? (suffixIcon || null) : null}
      onClick={onClick}
    >
      {loading ? <CircularProgress className={classes.circularProgress} size={20} /> : text}
    </Button>
  );
};

export default CustomButton;
