import { Box, Typography } from '@mui/material';
import React from 'react';

const DisplayInfoComponent = ({ title, subTitle }: { title: string, subTitle: string }) => (
    <Box display='flex' flexDirection='column' marginRight='55px'>
    <Box>
      <Typography
        variant='h4'
        color='tertiary'
      >
        {title}
      </Typography>

    </Box>
    <Box>
      <Typography
        variant='body1'
        color='tertiary'
      >
        {subTitle}
      </Typography>
    </Box>
  </Box>
);

export default DisplayInfoComponent;
