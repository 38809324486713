import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Close as CloseIcon, Check as CheckIcon, CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import CustomButton from '../../../../components/General/CustomButton';
import useStyles from '../../styles';

const UploadExcelModal = ({
  modalUploadStudentsInfo, handleCloseModal, handleUploadStudents, setFileToUpload,
}: {
  modalUploadStudentsInfo: boolean,
  handleCloseModal: () => void,
  handleUploadStudents: () => void,
  setFileToUpload: React.Dispatch<React.SetStateAction<File | undefined>>
}) => {
  const classes = useStyles();
  const [filename, setFilename] = useState('');
  const truncateFileName = (fileName: string) => (fileName.length > 35 ? `${fileName.substring(0, 32)}...` : fileName);

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFileToUpload(e.target.files[0]);
      setFilename(e.target.files[0].name);
    } else {
      setFilename('');
    }
  };

  const extendHandleCloseModal = () => {
    setFilename('');
    handleCloseModal();
  };

  return (
    <Modal
      open={modalUploadStudentsInfo}
      onClose={extendHandleCloseModal}
    >
      <Box className={classes.modalContainer}>
        <Card className={classes.uploadModal}>
          <CardContent className={classes.modalContent}>
            <Box className={classes.modalTitleContainer}>
              <Box className={classes.modalTitleAndIcon}>
                <Typography variant='h4' color='primary' fontWeight='bold'>Carga masiva alumnos</Typography>
              </Box>

              <IconButton onClick={extendHandleCloseModal}>
                <CloseIcon color='primary' />
              </IconButton>
            </Box>
            <Box className={classes.uploadModalMainContainer}>
              <label className={classes.labelItem}>
                <Button
                  fullWidth
                  variant="outlined"
                  component="span"
                  startIcon={<CloudUploadIcon />}
                >
                  {filename !== '' ? truncateFileName(filename) : 'Carga archivo'}
                </Button>
                <input
                  type='file'
                  hidden
                  onChange={(e) => handleSelectFile(e)}
                />
              </label>
            </Box>
            <Box className={classes.modalButtonsContainer}>
              <CustomButton text='Cancelar' colorType='tertiary' onClick={extendHandleCloseModal} key={'CancelButtonAddProgram'} icon={<CloseIcon />} />
              <CustomButton text='Confirmar' colorType='primary' onClick={handleUploadStudents} key={'AcceptButtonEdit'} icon={<CheckIcon />} />
            </Box>
          </CardContent>
        </Card>
      </Box>

    </Modal >
  );
};

export default UploadExcelModal;
