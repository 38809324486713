import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { clean, validate } from 'rut.js';

import { institutionLoginSuccess } from '../../actions/institutionAccountActions';
import handleApiResponse from '../../utils/handleApiResponse';
import authService from '../../services/authService';

import { InstitutionUser } from './types';
import { setUserData } from '../../actions/accountActions';
import { ADMINISTRATOR } from '../../utils/user_types';

function LoginForm({
  className,
  institutionId,
  setInstitutionUser,
  urlExtension,
  ...rest
}: {
  className?: string,
  setInstitutionUser: (institutionUser: InstitutionUser) => void,
  institutionId: number,
  urlExtension: string,
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        user: '',
        password: '',
      }}
      validationSchema={Yup.object().shape({
        user: Yup.string().required('El Rut es requerido'),
        password: Yup.string().max(255).required('La contraseña es requerida'),
      })}
      onSubmit={async (values, {
        setSubmitting,
      }) => {
        try {
          if (!validate(values.user)) {
            const key = enqueueSnackbar('El Rut no es válido', {
              variant: 'error',
              onClick: () => closeSnackbar(key),
            });
            return;
          }
          const response = await authService.loginInstitutionWithRutAndPassword(
            institutionId, clean(values.user), values.password,
          );

          if (response.success) {
            if (response.informationComplete) {
              // Clear previous user information from preu platform.
              const initialUser = {
                id: 0,
                email: '',
                name: '',
                lastname: '',
                userTypeId: '',
                userType: '',
              };
              dispatch(setUserData(initialUser));
              // Save institution user info.
              dispatch(institutionLoginSuccess(response.institutionUser));

              if (response.institutionUser.role === ADMINISTRATOR) {
                history.push(`/institucion/${urlExtension}/administracion`);
              } else {
                history.push(`/institucion/${urlExtension}/ensayos`);
              }
            }

            setInstitutionUser(response.institutionUser);
          }
        } catch (err) {
          const e = err as AxiosError;
          handleApiResponse(enqueueSnackbar, e, false);
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          className={clsx(className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <TextField
            error={Boolean(touched.user && errors.user)}
            color="secondary"
            fullWidth
            autoFocus
            helperText={touched.user && errors.user}
            label="Rut"
            placeholder="Rut sin puntos ni guion"
            margin="normal"
            name="user"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.user}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            color="secondary"
            helperText={touched.password && errors.password}
            label="Contraseña"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Box mt={2}>
            <Button
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ backgroundColor: '#E94745' }}
            >
              Ingresar
            </Button>
          </Box>
        </form>
      )
      }
    </Formik >
  );
}

export default LoginForm;
