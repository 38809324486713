import { Box } from '@mui/material';
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { PieChartProps } from '../types';

export const PieChart = ({ totalStudents, testsByCompletion }: PieChartProps) => {
  const pieChartOptions = {
    chart: {
      type: 'pie',
    },
    title: {
      text: 'Participación en los Ensayos',
    },
    tooltip: {
      valueSuffix: '%',
    },
    plotOptions: {
      pie: {
        size: 200,
      },
      series: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: [{
          enabled: true,
          distance: 20,
        }, {
          enabled: true,
          distance: -40,
          format: '{point.percentage:.2f}%',
          style: {
            fontSize: '1.2em',
            textOutline: 'none',
            opacity: 0.7,
          },
          filter: {
            operator: '>',
            property: 'percentage',
            value: 10,
          },
        }],
      },
    },
    series: [
      {
        name: 'Percentage',
        colorByPoint: true,
        data: [
          {
            name: 'No rindieron',
            y: totalStudents
              ? Math.round(((testsByCompletion.notCompleted / totalStudents)
                * 10000)) / 100
              : 0,
          },
          {
            name: 'Lenguaje',
            y: totalStudents
              ? Math.round(((testsByCompletion
                .completedLenguaje / totalStudents) * 10000)) / 100
              : 0,
          },
          {
            name: 'Matemáticas',
            y: totalStudents
              ? Math.round(((testsByCompletion
                .completedMatematicas / totalStudents)
                * 10000)) / 100
              : 0,
          },
          {
            name: 'Lenguaje y Matemáticas',
            y: totalStudents
              ? Math.round(((testsByCompletion
                .completedBoth / totalStudents) * 10000)) / 100
              : 0,
          },
        ],
      },
    ],
  };

  return (
    <Box style={{ width: '33%' }}>
      <HighchartsReact highcharts={Highcharts} options={pieChartOptions} />
    </Box>
  );
};
