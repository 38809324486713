import axios from '../../utils/axios';

const getInstitutionSessionUserTests = async (
  institutionUrlExtension: string, institutionUserId: string | number,
) => {
  const response = await axios.get(`/api/institution-test/${institutionUrlExtension}/${institutionUserId}`);

  return response.data;
};

const getInstitutionSessionUserTestInfo = async (institutionSessionUserTestId: string) => {
  const response = await axios.get(`/api/institution-test/${institutionSessionUserTestId}`);

  return response.data;
};

const postDownloadTestPDF = async (url: string) => {
  const response = await axios.post('/api/institution-test/download', { url });

  return response.data;
};

const getInstitutionSessionSummaryInfo = async (
  { institutionSessionId }: { institutionSessionId: string },
) => {
  const response = await axios.get(`/api/institution-test/institution-session/${institutionSessionId}/summary`);

  return response.data;
};

const getTestResultsPerPage = async (
  {
    page, limit, searchValue, filter, institutionSessionTestId,
  }: {
    institutionSessionTestId: string,
    page: number,
    limit: number,
    searchValue: string,
    filter: string,
  },
) => {
  const response = await axios.get(`/api/institution-test/institution-session-test/${institutionSessionTestId}/information?page=${page}&limit=${limit}&searchValue=${searchValue}&filter=${filter}`);

  return response.data;
};

const getSessionTestsGraphInformation = async (
  {
    filter,
    institutionSessionTestId,
  }: {
    filter: string, institutionSessionTestId: string,
  },
) => {
  const response = await axios.get(`/api/institution-test/institution-session-test/${institutionSessionTestId}/graphs?filter=${filter}`);

  return response.data;
};

const getSchools = async (institutionSessionTestId: string) => {
  const response = await axios.get(`/api/institution-test/institution-session-test/${institutionSessionTestId}/schools`);

  return response.data;
};

const getStatisticsInExcel = async (institutionSessionTestId: string, filter: string) => {
  const response = await axios.get(`/api/institution-test/institution-session-test/${institutionSessionTestId}/excel?filter=${filter}`, { responseType: 'blob' });

  return response.data;
};

const getQuestionsForTest = async (institutionSessionUserTestId: string) => {
  const response = await axios.get(`/api/institution-test/institution-session-user-test/${institutionSessionUserTestId}/test-questions`);

  return response.data;
};

const postSessionTestAnswers = async (
  institutionSessionUserTestId: string,
  userAnswers: { [key: string]: { answer: string | undefined, markedForRevision: boolean } },
) => {
  const response = await axios.post(`/api/institution-test/institution-session-user-test/${institutionSessionUserTestId}/answers`, {
    userAnswers,
  });

  return response.data;
};

const getInstitutionSessionUserTestResult = async (id: string) => {
  const response = await axios.get(`/api/institution-test/institution-session-test/${id}/result`);
  return response.data;
};

export {
  getInstitutionSessionUserTests,
  getInstitutionSessionUserTestInfo,
  postDownloadTestPDF,
  getInstitutionSessionSummaryInfo,
  getQuestionsForTest,
  getTestResultsPerPage,
  getSessionTestsGraphInformation,
  getSchools,
  getStatisticsInExcel,
  getInstitutionSessionUserTestResult,
  postSessionTestAnswers,
};
