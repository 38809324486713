import React, {
  forwardRef,
  useEffect,
  useCallback,
  ForwardRefRenderFunction,
} from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import track from '../../utils/analytics';
import { PageProps } from '../../types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
}));

const Page: ForwardRefRenderFunction<HTMLDivElement, PageProps> = (
  { title, children, ...rest },
  ref,
) => {
  const location = useLocation();
  const pageStyle = useStyles();

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: location.pathname,
    });
  }, [location]);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <div className={pageStyle.root} ref={ref} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
};

export default forwardRef(Page);
