export type Institution = {
  id: number | null,
  name: string,
  urlExtension: string,
  logoUrl: string,
  institutionSessions: {
    startDate: Date | string | null,
    endDate: Date | string | null,
    resultsDate: Date | string | null,
  }[],
};

export type InstitutionUser = {
  id: number | null,
  role: string,
  rut: string,
  password: string,
  name: string | null,
  lastname: string | null,
  email: string | null,
  phoneNumber: string | null,
  school: string | null,
  schoolYear: string | null,
  preferredUniversity: string | null,
  preferredCarrer: string | null,
  deleted: boolean,
};

export const UNIVERSITIES = [
  'Universidad De Valparaíso',
  'Universidad De Santiago De Chile',
  'Universidad De Antofagasta',
  'Universidad De Chile',
  'Universidad De Magallanes',
  'Universidad De Atacama',
  'Universidad De La Serena',
  'Universidad De Los Lagos',
  'Universidad Tecnológica Metropolitana',
  'Universidad De Tarapacá',
  'Universidad De La Frontera',
  'Universidad De Playa Ancha De Ciencias De La Educación',
  'Universidad Metropolitana De Ciencias De La Educación',
  'Universidad Arturo Prat',
  'Universidad Del Bío-bío',
  'Universidad De O`higgins',
  'Universidad De Aysén',
  'Universidad De Talca',
  'Universidad Católica Del Norte',
  'Universidad Católica Del Maule',
  'Universidad Católica De La Santísima Concepción',
  'Universidad Católica De Temuco',
  'Pontificia Universidad Católica De Chile',
  'Universidad De Concepción',
  'Pontificia Universidad Católica De Valparaíso',
  'Universidad Austral De Chile',
  'Universidad Técnica Federico Santa María',
  'Universidad Central De Chile',
  'Universidad Bolivariana',
  'Universidad De Artes, Ciencias Y Comunicación - Uniacc',
  'Universidad Autónoma De Chile',
  'Universidad De Viña Del Mar',
  'Universidad Mayor',
  'Universidad Andrés Bello',
  'Universidad Finis Terrae',
  'Universidad Gabriela Mistral',
  'Universidad Iberoamericana De Ciencias Y Tecnología, Unicit',
  'Universidad Sek',
  'Universidad Santo Tomás',
  'Universidad Adolfo Ibáñez',
  'Universidad Diego Portales',
  'Universidad Academia De Humanismo Cristiano',
  'Universidad De Las Américas',
  'Universidad La República',
  'Universidad Del Desarrollo',
  'Universidad Bernardo O`higgins',
  'Universidad Miguel De Cervantes',
  'Universidad Católica Cardenal Raúl Silva Henríquez',
  'Universidad Alberto Hurtado',
  'Universidad San Sebastián',
  'Universidad De Arte Y Ciencias Sociales Arcis',
  'Universidad Tecnológica De Chile Inacap',
  'Universidad Los Leones',
  'Universidad Del Pacífico',
  'Universidad De Aconcagua',
  'Universidad Adventista De Chile',
  'Universidad De Los Andes',
  'Universidad Del Alba',
  'Otro',
];

export const CARRERS = [
  'Administración de Empresas',
  'Administración Pública',
  'Agronomía',
  'Antropología',
  'Arquitectura',
  'Artes Visuales',
  'Astronomía',
  'Biología',
  'Bioquímica',
  'Ciencias Ambientales',
  'Ciencias Políticas',
  'Ciencias de la Familia',
  'Ciencias del Deporte',
  'Comunicación Audiovisual',
  'Contabilidad y Auditoría',
  'Criminología',
  'Derecho',
  'Diseño Gráfico',
  'Diseño de Interiores',
  'Economía',
  'Economía Internacional',
  'Educación Básica',
  'Educación Física',
  'Educación Parvularia',
  'Enfermería',
  'Enfermería Obstétrica',
  'Estadística',
  'Estudios Culturales',
  'Filosofía',
  'Fonoaudiología',
  'Geografía',
  'Geología',
  'Geotecnia',
  'Historia',
  'Ingeniería Aeroespacial',
  'Ingeniería Ambiental',
  'Ingeniería Biomédica',
  'Ingeniería Civil',
  'Ingeniería Comercial',
  'Ingeniería Eléctrica',
  'Ingeniería Industrial',
  'Ingeniería Mecánica',
  'Ingeniería en Biotecnología',
  'Ingeniería en Computación e Informática',
  'Ingeniería en Energías Renovables',
  'Ingeniería en Minas',
  'Ingeniería en Telecomunicaciones',
  'Ingeniería Química',
  'Lingüística',
  'Matemáticas Aplicadas',
  'Medicina',
  'Nanotecnología',
  'Nutrición y Dietética',
  'Oceanografía',
  'Odontología',
  'Paleontología',
  'Pedagogía en Inglés',
  'Pedagogía en Música',
  'Periodismo',
  'Psicología',
  'Química',
  'Sociología',
  'Terapia Ocupacional',
  'Trabajo Social',
  'Veterinaria',
  'Otro',
];

export const SCHOOLS = [
  'Centro Educ. Casa Talleres San Vicente De Pau',
  'Centro Educacional Integral De Adult Acuario',
  'Centro Educativo Salesianos Alameda',
  'Colegio Pedro De Valdivia-agustinas',
  'Colegio Adventista Porvenir',
  'Colegio Alto Palena',
  'Colegio Bicentenario Victoria Prieto',
  'Colegio Ctro. Integ Adultos Altazol',
  'Colegio De Adult.academia Estudios Miraflores',
  'Colegio De Adultos Altazol De Santiago',
  'Colegio De Adultos Gladys Lazo',
  'Colegio De Adultos Instituto Andes',
  'Colegio De Adultos Instituto Luisa Cardijin',
  'Colegio De Adultos Instituto Tabancura Sede Londres',
  'Colegio De Adultos Pulmahue',
  'Colegio De Los Sagrados Corazones Alameda',
  'Colegio Excelsior',
  'Colegio Filipense',
  'Colegio Francisco Arriaran',
  'Colegio Hispano Americano',
  'Colegio Hospital San Borja Arriaran',
  'Colegio Industrial Vasco Nunez De Balboa',
  'Colegio Instituto Alonso De Ercilla',
  'Colegio Instituto Artístico De Estudios Secundarios De La Universidad De Chile',
  'Colegio Lorenzo Sazie',
  'Colegio Maria Luisa Villalon',
  'Colegio Metodista De Santiago',
  'Colegio Nuestra Senora De Andacollo',
  'Colegio Nuevo Diego De Almagro',
  'Colegio Oratorio Don Bosco',
  'Colegio Part. Adultos Instituto Icel',
  'Colegio Particular María Auxiliadora De Santiago',
  'Colegio Particular Ozanam',
  'Colegio Saint Rose School',
  'Colegio San Antonio',
  'Colegio San Francisco De Asis De Belen',
  'Colegio San Ignacio',
  'Colegio San Sebastian',
  'Colegio Santa Cecilia',
  'Colegio Santa Cruz',
  'Colegio Santa Elena Carmelitas Misioneras Teresiana',
  'Colegio Santa Isabel',
  'Colegio Santa Maria De Santiago',
  'Colegio Santa Teresita Del Nino Jesus',
  'Colegio Wunman',
  'Esc. Especial Hospital San Juan De Dios',
  'Escuela Manuel Jose Irarrazaval',
  'Inst.sup.de Comercio Eduardo Frei M.',
  'Instituto Comercial Blas Canas',
  'Instituto Comercial Eliodoro Dominguez Dominguez',
  'Instituto Hermanos Matte',
  'Internado Nacional Barros Arana',
  'Liceo De Adultos Insieme',
  'Liceo 6 Bicentenario Teresa Prats',
  'Liceo Bicentenario Italia',
  'Liceo Comercial Joaquin Vera Morales',
  'Liceo Confederacion Suiza',
  'Liceo Dario Salas',
  'Liceo De Adultos Herbert Vargas Wallis',
  'Liceo De Adultos Part.fermin Vivaceta',
  'Liceo De Aplicacion Rector Jorge E Schne',
  'Liceo Dr Humberto Maturana Romesin',
  'Liceo Industrial A-22 De Santiago',
  'Liceo Industrial De La Construccion Victor Bezanilla Salinas',
  'Liceo Industrial Eliodoro Garcia Zegers',
  'Liceo Instituto Nacional',
  'Liceo Javiera Carrera',
  'Liceo Jose Miguel Infante',
  'Liceo José De San Martin',
  'Liceo Manuel Barros Borgono',
  'Liceo Miguel De Cervantes Y Saavedra',
  'Liceo Municipal Metropolitano De Adultos',
  'Liceo Nro 2 Miguel Luis Amunategui',
  'Liceo N° 4 Bicentenario Isaura Dinator',
  'Liceo Polit. Sara Blinder Dargoltz',
  'Liceo Politec. Pdte. Gabriel Gonzalez Videla',
  'Liceo Polivalente A-28 Emilia Toro De Bal',
  'Liceo Profesional Abdon Cifuentes',
  'Liceo Republica De Brasil',
  'Liceo Tecnico A N° 27 Clelia Clavel Dinat',
  'Liceo Y Colegio Nuevo Hispano Chileno',
  'Colegio Bicentenario De Excelencia Politecnico Aleman Albert Einstein',
  'Colegio Industrial Part. Don Orione',
  'Colegio Los Conquistadores',
  'Colegio Particular Mater Dei',
  'Colegio Peter College',
  'Colegio Saint Maurice S',
  'Colegio San Jose De Cerrillos',
  'Liceo Polivalente Dr. Luis Vargas Salcedo',
  'Ceia Georgina Salas Dinamarca',
  'Centro Educacional De Adultos',
  'Colegio Bicentenario San Damian De Molokai',
  'Colegio Enrique Alvear De Cerro Navia',
  'Colegio Poliv. Saint Orland N 3',
  'Colegio San Francisco Javier',
  'Complejo Educacional Cerro Navia',
  'Liceo Politecnico San Francisco Solano',
  'Liceo Polivalente Los Heroes De La Concepción',
  'Centro Politecnico Particular Conchali',
  'Colegio Cristobal Colon',
  'Colegio De Adultos Carelmapu De Conchali',
  'Colegio Educaadultos Conchali',
  'Colegio Hellen´s College',
  'Colegio Libertador San Martin',
  'Escuela Part. Adventista Santiago Norte',
  'Liceo Alberto Blest Gana',
  'Liceo Munc.almirante Galvarino Riveros',
  'Liceo Poeta Federico Garcia Lorca',
  'Liceo Poliv. Abdon Cifuentes',
  'Centro Educ. Denver Colorado School',
  'Centro Educacional Matias Cousino',
  'Colegio De Adultos Jose Abelardo Nunez El Bos',
  'Colegio El Almendro',
  'Colegio Juan Bautista Duran',
  'Colegio Part. Ntra. Sra. Maria Inmaculada Del',
  'Colegio Particular Tantauco El Bosque',
  'Colegio Paul Harris School',
  'Colegio Politec. Maria Griselda Valle S.',
  'Colegio Polivalente Agustiniano De El Bosque',
  'Colegio Santa Barbara College',
  'Colegio Santo Tomas De Aquino',
  'Colegio Teniente Dagoberto Godoy',
  'Colegio Villa Santa Maria',
  'Complejo Educ.particular De Las Acacias',
  'Complejo Educacional Felipe Herrera Lane',
  'Complejo Educacional Stella Maris',
  'Escuela Basica Base El Bosque',
  'Escuela Basica The Forest College',
  'Escuela Particular Villa El Sol',
  'Escuela Y Liceo Liahona',
  'Liceo Christa Mc Auliffe',
  'Liceo Fray Luis Beltran',
  'Liceo Juan Gomez Millas',
  'Centro Educ.munic. Dr. Amador Neghme Rodriguez',
  'Colegio De Ad. Santa Maria Del Trabajo De Est',
  'Colegio Echaurren',
  'Colegio Franciscano Maria Reina',
  'Colegio Greenland School',
  'Colegio Part. Ascension Nicol',
  'Colegio Piamarta',
  'Colegio Polivalente Principe De Gales',
  'Colegio San Alberto',
  'Colegio Sao Paulo',
  'Escuela Del Cariño Ricardo Navia',
  'Escuela Hospitalaria Clínica Red Salud Santiago',
  'Escuela Liceo Madre Vicencia',
  'Escuela Particular Jose Antonio Lecaros',
  'Escuela Particular Villa Espana',
  'Escuela, Colegio Politec.h.de Magallanes',
  'Liceo Bicentenario Polivalente A N°71 Guillermo Feliu Cruz',
  'Liceo Comercial B-72',
  'Liceo De Adultos Municipalizado Luis Gomez Catalan',
  'Liceo Estacion Central',
  'Liceo Ruiz Tagle',
  'Centro Educacional Bicentenario De Excelencia De Huechuraba',
  'Colegio Pumahue Huechuraba',
  'Colegio De Adultos Jose Abelardo Nunez',
  'Colegio Grace College',
  'Colegio Lincoln College Huechuraba',
  'Colegio Mater Dolorosa',
  'Colegio Montahue',
  'Colegio Saint Joseph School',
  'Colegio San Francisco Javier De Huechuraba',
  'Colegio Santa Teresa De Jesus De Los Andes',
  'Liceo Jose Abelardo Nuñez Nº 4',
  'Colegio Arturo Toro Amor',
  'Colegio Cientifico Humanista Alvaro Covarrubias',
  'Colegio Elena Bettini',
  'Colegio George Washington',
  'Colegio Particular Juan Pablo',
  'Colegio Politecnico Avda. Independencia',
  'Colegio Polivalente Plus Ultra',
  'Colegio Presidente Alessandri',
  'Colegio Santa Maria Cervellon',
  'Escuela Tecnica L.bdo.o´higgins-diurna',
  'Liceo Adultos Part.las Americas Modernas',
  'Liceo Gabriela Mistral',
  'Liceo Miguel Rafael Prado',
  'Liceo Multigenero Doctora Eloisa Diaz Inzunza',
  'Liceo Polivalente A80 Presidente Jose Manuel Balmaceda',
  'Liceo San Francisco De Quito',
  'Liceo Santa Teresita',
  'Centro Educacional Santa Clara',
  'Centro Educacional Santa Rosa',
  'Centro Politec. Carlos Condell De La Cisterna',
  'Centro Politecnico Particular San Ramon',
  'Chilean Eagles College - La Cisterna',
  'Colegio Adventista La Cisterna',
  'Colegio Antil Mawida',
  'Colegio Bicentenario De Excelencia Miguel De Cervantes',
  'Colegio Idop Limitada',
  'Colegio Jose Luis Legrange De La Cisterna',
  'Colegio Poliv. Saint Orland',
  'Colegio Princess Anne School',
  'Colegio Santa Isabel De Hungria',
  'Colegio Santa Maria De Los Angeles',
  'Colegio Tec. Prof. San Ramon La Cisterna',
  'Escuela Pre Militar Heroes De La Concepcion',
  'Froilan Yañez De La Barra',
  'Instituto Comercial Particular La Cisterna',
  'Liceo Bicentenario Industrial De Electrotecnia Ramon B',
  'Liceo Laura Vicuna',
  'Liceo Madre Cecilia Lazzeri',
  'Liceo Part. Salesiano Manuel Arriaran Barros',
  'Liceo Politecnico Ciencia Y Tecnologia',
  'Liceo Polivalente Olof Palme',
  'Liceo Portal De La Cisterna',
  'Liceo Técnico Industrial La Cisterna',
  'Bernadette College',
  'Bicentenario College',
  'Centro Educacional De Adultos Rafael Sotomayo',
  'Centro Educacional La Florida',
  'Centro Educacional London',
  'Colegio Artistico Sol Del Illimani',
  'Colegio Latinoamericano',
  'Colegio Pablo Apostol',
  'Colegio Adventista La Florida',
  'Colegio Alicante De La Florida',
  'Colegio Almendral',
  'Colegio American British College',
  'Colegio Antuquenu Andino',
  'Colegio Artistico El Salvador Anexo',
  'Colegio Atenas',
  'Colegio Boston College Alto Macul',
  'Colegio Cristiano Emmanuel',
  'Colegio De Adultos Hernando De Magallanes',
  'Colegio De Adultos London De La Florida',
  'Colegio Divina Pastora',
  'Colegio Emmanuel High School',
  'Colegio Florida High School',
  'Colegio Hugo Landauro Henriquez',
  'Colegio Le Monde',
  'Colegio Liahona Cordillera',
  'Colegio Licarayen',
  'Colegio Mauricio Rugendas',
  'Colegio Nazaret De La Florida',
  'Colegio Nova Terra',
  'Colegio Part. Alcantara De La Cordillera',
  'Colegio Part. Alcantara De La Florida',
  'Colegio Part. Andares De La Florida',
  'Colegio Part. Familia De Nazareth',
  'Colegio Part. Manantial De La Florida',
  'Colegio Part. New Little College',
  'Colegio Part. Patrona Senora De Lourdes',
  'Colegio Particular Antilhue',
  'Colegio Particular La Concepcion',
  'Colegio Particular Nueva Era Siglo Xxi',
  'Colegio Particular San Francisco De Asis',
  'Colegio Particular The Garden School',
  'Colegio Poliv. San Sebastian De La Florida',
  'Colegio Poliv. Vicente Valdes',
  'Colegio Polivalente Lovaina',
  'Colegio Quinto Centenario Cordillera',
  'Colegio Rocket Adultos De La Florida',
  'Colegio Saint Charles College La Florida',
  'Colegio Saint Joseph C. Thomas School',
  'Colegio San Cristobal De Las Casas',
  'Colegio San Damian',
  'Colegio Santa Cecilia De La Florida',
  'Colegio Santa Maria',
  'Colegio Santa Maria De Lo Canas',
  'Colegio Santa Patricia De La Florida',
  'Colegio Santiago',
  'Colegio Semper Altius',
  'Complejo Educ. Municipal Carden.a.samore',
  'Esc Basica Particular San Valentin',
  'Escuela Belen O¦higgins',
  'Escuela Bellavista',
  'Escuela Hospitalaria Doctora Eloisa Diaz Insunza',
  'Escuela Marcela Paz',
  'Escuela Particular Msr.diego Rosales',
  'Escuela Particular Philippe Cousteau',
  'Escuela Particular Rosario Concha',
  'Escuela Raimapu-tierra Florida',
  'Escuela Y Colegio Politecnico Los Heroes',
  'Instituto La Salle',
  'Liceo Alto Cordillera De La Florida',
  'Liceo Andres Bello',
  'Liceo Bicentenario De Excelencia Benjamin Vicuna Mackenna',
  'Liceo Indira Gandhi',
  'Liceo Nuevo Amanecer',
  'Liceo Polivalente Los Almendros',
  'Liceo Polivalente Municipal De La Florida',
  'Liceo Vespertino De Adultos Futuro',
  'Lincoln College La Florida',
  'Pierre Teilhard De Chardin',
  'Profesor Francisco Vergara Bobadilla',
  'Colegio Alma Mater',
  'Colegio Betania',
  'Colegio Christian Garden School',
  'Colegio Los Pensamientos',
  'Colegio Polivalente Padre Esteban Gumucio Vives',
  'Colegio San Gregorio De La Salle',
  'Escuela Del Cariño Saint Christian',
  'Escuela Particular Claudio Matte',
  'Liceo Bicentenario Francisco Frias Valenzuela',
  'Liceo Bicentenario Nuestra Señora De Guadalupe',
  'Liceo Doctor Alejandro Del Rio',
  'Liceo Malaquias Concha',
  'Liceo Municipal La Granja Poeta Neruda',
  'Liceo Polivalente Bahia Darwin',
  'Liceo Tecnico Profesional Patricio Aylwin Azocar',
  'Saint Christian College',
  'Centro Educacional Mun.mariano Latorre',
  'Centro Educacional Santa Rosa Del Sur',
  'Colegio Alto Gabriela',
  'Colegio Australia',
  'Colegio Bicentenario Aprender',
  'Colegio De Ad. Instituto Humboldt',
  'Colegio Instituto San Carlos',
  'Colegio Jorge Huneeus Zegers',
  'Colegio King School Cordillera',
  'Colegio Marista Marcelino Champagnat',
  'Colegio Poliv. Almendral De La Pintana',
  'Colegio Poliv. Cardenal Jose Maria Caro',
  'Colegio Poliv. Nueva Nazaret',
  'Colegio Polivalente Ingles',
  'Colegio San Martin De Porres De La Pintana',
  'Colegio Santa Sofia',
  'Colegio Santo Tomas',
  'Colegio Simon Bolivar',
  'Colegio Tecnico Profesional Nocedal',
  'Ctro.educ.catolico Didascalio Sta. Maria',
  'Esc Basica Part San Jose De La Familia',
  'Esc. Basica Part.iberoamericano',
  'Esc. Part. Celestin Freinet',
  'Esc.basica Prof.aurelia Rojas Burgos',
  'Escuela Basica Oscar Salinas',
  'Escuela Particular Pdte.jose J.prieto',
  'Liceo De Adultos Ceia De La Pintana',
  'Liceo Municipal El Roble',
  'Liceo Sergio Silva Bascunan',
  'Col. Part. Sagrado Corazon De Jesus De La Re',
  'Colegio Andree English School',
  'Colegio British Royal School',
  'Colegio Coronel Eleuterio Ramirez Molina',
  'Colegio De La Salle',
  'Colegio El Carmen Teresiano',
  'Colegio Etievan',
  'Colegio Hospitalario Hospital Militar',
  'Colegio John Andrews Adventist Academy',
  'Colegio La Abadia',
  'Colegio Larrain',
  'Colegio Municipalizado Confederac Suiza',
  'Colegio Nuestra Señora Del Camino',
  'Colegio Real',
  'Colegio Ruben Dario',
  'Colegio Saint John´s Villa Academy',
  'Colegio San Rafael Arcangel',
  'Colegio Santa Catalina Labouré',
  'Colegio Santiago Evangelista',
  'Colegio Santo Domingo_padres Dominicos',
  'Colegio Sta Maria Reina',
  'Colegio Superior Cambridge',
  'Colegio Teresiano Enrique De Osso',
  'Colegio Terranova',
  'Colegio The Grange School',
  'Complejo Educacional La Reina',
  'Liceo Eugenio María De Hostos',
  'Centro Educacional Life Support',
  'Colegio Alcazar De Las Condes',
  'Colegio Aleman De Santiago',
  'Colegio Andino Antillanca',
  'Colegio Arabe',
  'Colegio British High',
  'Colegio Ciudadela Montessori De Las Condes',
  'Colegio Compania De Maria Apoquindo',
  'Colegio Concordia',
  'Colegio Cordillera De Las Condes',
  'Colegio Coyancura',
  'Colegio Creces',
  'Colegio Cumbres',
  'Colegio Dalcahue',
  'Colegio Del Sagrado Corazon De Apoquindo',
  'Colegio Del Verbo Divino',
  'Colegio Dunalastair',
  'Colegio Emaus',
  'Colegio Institucion Teresiana',
  'Colegio Internacional Sek Chile',
  'Colegio La Girouette',
  'Colegio La Virgen De Pompeya',
  'Colegio Leonardo Da Vinci De Las Condes',
  'Colegio Ntra. Sra. Del Rosario Las Condes',
  'Colegio Nuestra Senora Del Loreto',
  'Colegio Padre Hurtado Y Juanita De Los Andes',
  'Colegio Pedro De Valdivia Las Condes',
  'Colegio Pucara De Los Andes',
  'Colegio Quimay',
  'Colegio Redland School',
  'Colegio Saint Andrew',
  'Colegio San Francisco De Asis',
  'Colegio San Francisco Del Alba',
  'Colegio San Francisco Tecnico Profesional',
  'Colegio San Jorge De Las Condes',
  'Colegio San Juan Evangelista',
  'Colegio San Luis De Las Condes',
  'Colegio San Miguel Arcangel De Las Condes',
  'Colegio San Nicolas De Myra',
  'Colegio Scuola Italiana Vittorio Montiglio',
  'Colegio Seminario Pontificio Menor',
  'Colegio Tec.hoteleria Y Gastronomia Achiga Co',
  'Colegio The Southern Cross School',
  'Colegio Villa Maria Academy',
  'Colegio Wenlock School',
  'Colegio William Kilpatrick',
  'Instituto Docencia',
  'Instituto Presidente Errazuriz',
  'Liceo Alexander Fleming De Las Condes',
  'Liceo Bicentenario Santa Maria De Las Condes',
  'Liceo Bicentenario Simon Bolivar',
  'Liceo Juan Pablo Ii De Las Condes',
  'Liceo Rafael Sotomayor',
  'San Juan De Las Condes',
  'Colegio Anglo American International School',
  'Colegio Bertait College',
  'Colegio Betterland School',
  'Colegio Bicentenario San Esteban Martir',
  'Colegio Craighouse',
  'Colegio Everest',
  'Colegio Huelquen Montessori',
  'Colegio Huinganal',
  'Colegio Lincoln International Academy',
  'Colegio Lo Barnechea Bicentenario',
  'Colegio Los Alerces',
  'Colegio Maimonides School',
  'Colegio Nido De Aguilas',
  'Colegio Parroquial Santa Rosa De Lo Barnechea',
  'Colegio Polivalente San Rafael',
  'Colegio Santiago College',
  'Colegio Tabor Y Nazareth',
  'Colegio The Mayflower School',
  'Colegio The Newland School',
  'Colegio The Southland',
  'Educar Lo Barnechea',
  'Instituto Fermin Vivaceta',
  'Instituto Hebreo Dr Chaim Weizmann-ort',
  'Centro Duc.cardenal Jose Maria Caro',
  'Colegio Educad Lo Espejo',
  'Colegio Poliv. Saint Trinity College',
  'Colegio San Lucas De Lo Espejo',
  'Colegio Sol De Chile',
  'Complejo Educ. Adultos Caleta Sur',
  'Esc. Part.adventista Buenaventura',
  'Escuela Bas. Rosa Elvira Matte De Prieto',
  'Liceo Politecnico Part.sagrado Corazon',
  'Liceo Polivalente',
  'Liceo Teniente Francisco Mery Aguirre',
  ' Colegio Adventista Santiago Poniente',
  'Complejo Educacional Pedro Prado',
  'Esc. Bas. Y Esp. Celei',
  'Escuela Centro Educ.integral De Adultos',
  'Escuela Particular Frings College',
  'Liceo Bicentenario Poeta Pablo Neruda',
  'Liceo Profesora Gladys Valenzuela',
  'Liceo Teniente Dagoberto Godoy No.3',
  'Colegio De Ad. Bartolome De Las Casas De Macu',
  'Colegio De Adultos Rocket',
  'Colegio Libertador Simon Bolivar',
  'Colegio Madre De La Divina Providenc',
  'Colegio Part. Swedenborg College',
  'Colegio Particular El Alba De Macul',
  'Colegio Poliv. Rocket College',
  'Colegio Saint Mary Joseph School',
  'Colegio San Marcos',
  'Colegio San Viator',
  'Complejo Educacional Esperanza',
  'Escuela Particular Regina Mundi',
  'Instituto De Educacion De Adultos Macul',
  'Liceo Bicentenario Joaquin Edwards Bello',
  'Liceo Polivalente Mercedes Marin Del Solar',
  'Liceo Salesiano Camilo Ortuzar Montt',
  'Liceo Villa Macul Academia Ex Liceo Juana De Ibarbourou A 48',
  'Centro De Educ. Tecn.profesional Codeduc',
  'Centro De Educación De Adultos Bernardo O´higgins De Maipú',
  'Centro Educacional Piamartino Carolina Llona De Cuevas',
  'Col.particular San Valentin De Maipu',
  'Colegio Adultos Alfred Nobel',
  'Colegio Alberto Perez',
  'Colegio Alvaro Lavín',
  'Colegio Anglo Maipu',
  'Colegio Centenario',
  'Colegio Centro De Educ Perm.de Adultos S',
  'Colegio Centro Educacional Rousseau',
  'Colegio De Ad. Educap',
  'Colegio De Adultos Los Libertadores De Maipu',
  'Colegio De Adultos Presbiteriano De Maipu',
  'Colegio De La Providencia C.larrain De I',
  'Colegio Del Real',
  'Colegio Del Valle',
  'Colegio El Redentor',
  'Colegio Ellen College',
  'Colegio Esc. Jose Manso De Velasco',
  'Colegio Hermanos Carrera De Chile',
  'Colegio Instituto America',
  'Colegio Instituto O¦higgins De Maipu',
  'Colegio Instituto Ramón Freire',
  'Colegio Intercultural Trememn',
  'Colegio Internacional Alba',
  'Colegio Jacques Cousteau',
  'Colegio Los Alpes Maipu',
  'Colegio Ntra. Sra. Del Carmen De Maipu',
  'Colegio Nuevos Castanos De Maipu',
  'Colegio Oxford',
  'Colegio Part. Alicante Del Rosal',
  'Colegio Part. Cardenal Carlos Oviedo Cavada',
  'Colegio Part. De Adultos Itsa',
  'Colegio Part. King Edwards School I',
  'Colegio Part. Royal American School',
  'Colegio Particular Alicante',
  'Colegio Particular De Adultos Santa Maria Del Trabajo',
  'Colegio Particular San Felix',
  'Colegio Poliv. Diego Portales De Maipu',
  'Colegio Polivalente Camilo Henriquez',
  'Colegio Polivalente Patricio Mekis',
  'Colegio Premilitar General San Martin De Maipu',
  'Colegio Rosita Sanchez',
  'Colegio Saint Louis School',
  'Colegio San Andres De Maipu',
  'Colegio San Andres Maipu El Bosque',
  'Colegio San Francisco De Sales',
  'Colegio San Francisco Misionero',
  'Colegio San Juan Diego De Guadalupe',
  'Colegio San Nicolas De Maipu',
  'Colegio Santa Maria De Maipu',
  'Colegio Santa Ursula',
  'Colegio Sofia Infante Hurtado',
  'Colegio Terraustral Del Sol',
  'Colegio Terraustral Oeste S.a',
  'Colegio Victoriano',
  'Complejo Educacional Alberto Widmer',
  'Complejo Educacional Maipu',
  'Esc. Basica Boston College Maipu',
  'Esc. Basica Part. Boston College La Farfana',
  'Esc. Part. Artistica El Trigal',
  'Escuela Bas. Echaurren N° 2',
  'Escuela General Ohiggins',
  'Escuela Hospitalaria El Carmen De Maipu',
  'Escuela Internacional De Lideres Coronel Santiago Bueras Y Avaria',
  'Escuela Particular Ruben Dario',
  'Instituto Para Adultos Costeau',
  'Instituto Cristiano Luis Gandarillas',
  'Liceo Bicentenario De Excelencia Politécnico Hannover',
  'Liceo Bicentenario De Niñas De Maipu',
  'Liceo El Llano De Maipu',
  'Liceo Experimental Artistico B-65',
  'Liceo Industrial Alberto Widmer',
  'Liceo Industrial Domingo Matte Perez',
  'Liceo Jose Ignacio Zenteno',
  'Liceo Maipú De Las Artes Y La Tecnología',
  'Liceo Mater Purissima',
  'Liceo Nacional De Maipu',
  'Liceo Reino De Dinamarca',
  'Liceo San Pedro Poveda',
  'Liceo Santiago Bueras Y Avaria',
  'Liceo Tecnologico Bicentenario Enrique Kirberg Baltiansky',
  'Lincoln College San Martin',
  'Centro Educ Profes Gmo Gonzalez Heinrich',
  'Centro Politecnico Particular De Nunoa',
  'Col Tec Profesional Republica Argentina',
  'Colegio Akros',
  'Colegio Apostol San Pedro',
  'Colegio Calasanz',
  'Colegio Divina Pastora Nunoa',
  'Colegio El Roble',
  'Colegio Especial Hospitalario De Carabineros',
  'Colegio Francisco Encina',
  'Colegio Henry Fayol',
  'Colegio Instituto Santa Maria',
  'Colegio Isabel La Catolica',
  'Colegio La Fontaine',
  'Colegio Manquecura Ñuñoa',
  'Colegio Maya School Anexo',
  'Colegio Part. New Heinrich High School',
  'Colegio Particular N 1 De Nunoa',
  'Colegio Preciosa Sangre',
  'Colegio Saint Gaspar College',
  'Colegio San Agustin',
  'Colegio Santa Gema Galgani',
  'Colegio Santa Margarita De Escocia',
  'Colegio Santa Marta',
  'Colegio Suizo De Santiago',
  'Colegio The Angel´s School',
  'Colegio Universitario El Salvador',
  'Colegio Victor Domingo Silva',
  'Colegio Waldorf De Santiago',
  'Complejo Educ.mun. Brigida Walker.',
  'Escuela Particular San Juan Bautista',
  'Instituto Pablo Neruda',
  'Instituto Superior De Comercio Diego Port',
  'Liceo Augusto D Halmar',
  'Liceo Bicentenario Carmela Silva Donoso',
  'Liceo Comercial Gabriel Gonzalez Videla',
  'Liceo Comercial Particular Ñuñoa',
  'Liceo Experimental Manuel De Salas',
  'Liceo Industrial Bicentenario Chileno Aleman',
  'Liceo Jose Toribio Medina',
  'Liceo Lenka Franulic',
  'Liceo Politecnico A N° 60 Presidente Manuel Montt',
  'Liceo Politecnico Pedro De Valdivia',
  'Liceo Republica De Siria',
  'Liceo Tecnico B Nõ 58 Jose Maria Narbona',
  'C.e.i.a.pedro Aguirre Cerda',
  'Colegio De Ad. Antonio Acevedo',
  'Colegio Grace School',
  'Complejo Educ.part.monsenor Luis A.perez',
  'Esc.bas.profesora Aida Ramos Diaz',
  'Escuela Consolidada Davila',
  'Escuela Particular Jose A.alfonso',
  'Est. Educ. Part. Arcangel Gabriel N° 2',
  'Instituto Comercial Padre Alberto Hurtado De Pedro Aguirre Cerda',
  'Liceo Municipal Enrique Backausse',
  'Liceo Polivalente Eugenio Pereira Salas',
  'Liceo Técnico Clotario Blest Riffo',
  'Centro Educ. Diego De Almagro',
  'Centro Educ. Valle Hermoso',
  'Centro Educ.adultos Americo Vespucio',
  'Centro Educacion Mariano Egana',
  'Centro Educacional Eduardo De La Barra',
  'Centro Educacional Fundacion Paula Jaraquemad',
  'Centro Educacional Integral De Adultos Erasmo Escala Arriagada (ceia))',
  'Colegio Epullay',
  'Colegio Mayor De Peñalolen',
  'Colegio Alcántara De Los Altos De Peñalolen',
  'Colegio Alicura',
  'Colegio Altamira',
  'Colegio Bicentenario Matilde Huici Navas',
  'Colegio De Adultos Antu-anay',
  'Colegio El Encuentro',
  'Colegio Francisco De Miranda',
  'Colegio Highlands Montessori School Of Santiago',
  'Colegio Jesus Servidor',
  'Colegio Likankura De Peñalolén',
  'Colegio Montahue De Peñalolen',
  'Colegio Monteverde',
  'Colegio Part. Altamira-adultos',
  'Colegio Part. Antupiren',
  'Colegio Particular Jorge Prieto Letelier',
  'Colegio Particular San Fernando',
  'Colegio Pedro De Valdivia -peñalolen',
  'Colegio Polivalente York',
  'Colegio Puelmapu',
  'Colegio Pumahue',
  'Colegio, Centro Educ.americo Vespucio',
  'Escuela Bas. San Jose De Penalolen',
  'Escuela Basica N°1889 Barrie Montessori Nº 2',
  'Escuela Basica N°2145 Colegio Dunalastair Peñalolén',
  'Escuela Basica N°2459, Francisco Varela',
  'Escuela Basica Y Especial N° 2078 Genesis 2005',
  'Escuela Básica Nº2490, Colegio Internacional Sek Austral',
  'Escuela Luis Arrieta Canas',
  'Escuela Particular Santa Victoria',
  'Escuela Santa Maria De Penalolen',
  'Escuela Tobalaba',
  'Fundación Educacional Colegio Notre Dame',
  'Instituto Nacional De Rehabilitacion Pac',
  'Liceo Antonio Hermida Fabres',
  'Liceo Juan Bautista Pasten',
  'Liceo Politec.particular Carmen Arriaran',
  'Col. Part. Sales. El Patrocinio De San Jose',
  'Colegio Instituto Pascal',
  'Colegio Aleman Sankt Thomas Morus',
  'Colegio Aula Clinica Santa Maria',
  'Colegio Cambridge College',
  'Colegio Campvs College',
  'Colegio Compania De Maria-seminario',
  'Colegio De Ad. Instituto San Sebastian De Bel',
  'Colegio De Adultos Casa De Estudios Futuro',
  'Colegio De Adultos Instituto Nuevo Bilbao',
  'Colegio De Los Sagrados Corazones Providencia',
  'Colegio Especial Hospitalario Con Todo El Cor',
  'Colegio Hospitalario Clinica Indisa',
  'Colegio Instituto De Humanidades Luis Campino',
  'Colegio Kendal English School Anexo',
  'Colegio Latinoamericano De Integracion',
  'Colegio Maria Inmaculada',
  'Colegio Mariano',
  'Colegio Part. Josefino Santisima Trinidad',
  'Colegio Particular Regina Pacis',
  'Colegio Pedro De Valdivia',
  'Colegio Poliv. Prof. Guillermo Gonzalez Hein',
  'Colegio Saint Gabriel´s School Local Sede',
  'Colegio San Ignacio El Bosque',
  'Colegio The English Institute',
  'Colegio The Kent School',
  'Colegio Trewhela´s English School',
  'Colegio Universitario Ingles',
  'Escuela Basica Juan Pablo Duarte',
  'Escuela Especial Hospital Felix Bulnes',
  'Escuela Y Liceo Paulina Von Mallinckrodt',
  'Instituto De Tabancura De Providencia',
  'Instituto San Sebastian De Providencia',
  'Liceo B 42 Tajamar',
  'Liceo Carmela Carvajal De Prat',
  'Liceo Jose Victorino Lastarria',
  'Liceo Nº7 De Providencia Luisa Saavedra',
  'Liceo Polivalente Arturo Alessandri P.',
  'Wexford College N 2',
  'Colegio Manquecura Ciudad De Los Valles',
  'Colegio Bicentenario Madre Ana Eugenia',
  'Colegio Bicentenario San Alberto Hurtado De Pudahuel',
  'Colegio El Prado',
  'Colegio Hospítalario Casabierta Coaniquem',
  'Colegio Poliv. Geronimo De Alderete',
  'Colegio Poliv. San Luis Beltran',
  'Colegio Pudahuel',
  'Colegio San Felipe',
  'Colegio Santiago De Pudahuel',
  'Colegio Terra Australis',
  'Escuelas Del Cariño Alborada',
  'Instituto Tecnológico Bicentenario De Excelencia San Mateo',
  'Liceo Bicentenario Monsenor Enrique Alvear',
  'Liceo Ciudad De Brasilia',
  'Liceo De Adultos Alberto Galleguillos J.',
  'Liceo Mun. Centro Educacional Pudahuel',
  'Lincoln College Pudahuel',
  'Colegio Manquecura Valle Lo Campino',
  'Colegio San Carlos De Quilicura',
  'Colegio Juan Luis Undurraga Aninat',
  'Colegio Padre Pedro Arrupe Sagrada Familia',
  'Colegio Palmares',
  'Colegio Part. San Adrian De Quilicura',
  'Colegio Part. San Pedro De Quilicura',
  'Colegio Particular Melford College',
  'Colegio Particular Novo Mundo',
  'Colegio Particular San Sebastian De Quilicura',
  'Colegio Poliv. Santa Maria',
  'Colegio Polivalente Novo Horizonte',
  'Colegio Polivalente San Pedro De Quilicura',
  'Colegio San Alberto Hurtado',
  'Colegio San Isaac Jogues',
  'Colegio Santa Barbara H.s.',
  'Colegio Santiago - Quilicura',
  'Colegio Tercer Milenio',
  'Colegio Weston Academy',
  'Complejo Educacional J. Miguel Carrera',
  'Escuela Básica Palmares Oriente',
  'Escuela Luis Cruz Martinez',
  'Liceo Alcalde Jorge Indo',
  'Liceo Bicentenario De Excelencia Francisco Bilbao Barquin De Quilicura',
  'Liceo De Adultos Poeta Vicente Huidobro',
  'Liceo Jose Domingo Canas',
  'Liceo Paula Jaraquemada De Quilicura',
  'Otro',
];
