import { Box } from '@mui/material';
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { StackBarChartProps } from '../types';

export const StackBarChart = ({ testsBySubjectAndLocation } : StackBarChartProps) => {
  const stackedBarChartOptions = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Ensayos rendidos',
      align: 'left',
    },
    xAxis: {
      categories: ['Matemáticas', 'Lenguaje'],
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Número ensayos',
      },
      stackLabels: {
        enabled: true,
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [
      {
        name: 'Presencial',
        data: [
          testsBySubjectAndLocation?.MATEMATICA.inPerson || 0,
          testsBySubjectAndLocation?.LENGUAJE.inPerson || 0,
        ],
      },
      {
        name: 'Online',
        data: [
          testsBySubjectAndLocation?.MATEMATICA.online || 0,
          testsBySubjectAndLocation?.LENGUAJE.online || 0,
        ],
      },
    ],
  };

  return (
    <Box style={{ width: '33%' }}>
    <HighchartsReact highcharts={Highcharts} options={stackedBarChartOptions} />
  </Box>
  );
};
