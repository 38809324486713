const INSTITUTIONS_SESSIONS_MODAL_UI = [
  {
    label: 'Institución', key: 'institutionName', typeField: 'text', disabled: true, showOnModal: true,
  },
  {
    label: 'Jornada', key: 'name', typeField: 'text', showOnModal: true,
  },
  {
    label: 'Fecha inicio', key: 'startDate', typeField: 'date', showOnModal: true,
  },
  {
    label: 'Fecha fin', key: 'endDate', typeField: 'date', showOnModal: true,
  },
  {
    label: 'Fecha entrega', key: 'resultsDate', typeField: 'date', showOnModal: true,
  },
  {
    label: 'Activar/Desactivar', key: 'active', typeField: 'switch', showOnModal: true, switchLabels: { on: 'Activa', off: 'Inactiva' },
  }];

const INSTITUTIONS_SESSIONS_UI = [
  {
    label: 'Jornada', key: 'name', valueType: 'string',
  },
  {
    label: 'Fecha inicio', key: 'startDate', valueType: 'string',
  },
  {
    label: 'Fecha fin', key: 'endDate', valueType: 'string',
  },
  {
    label: 'Fecha entrega', key: 'resultsDate', valueType: 'string',
  },
  {
    label: 'Activar/Desactivar', key: 'isSessionActive', valueType: 'other',
  },
  {
    label: 'Resultados', key: 'results', valueType: 'other',
  },
  {
    label: 'Ver alumnos', key: 'seeStudents', valueType: 'other',
  },
  {
    label: 'Acciones', key: 'actions', valueType: 'other',
  },
];

const INSTITUTIONS_ADMIN_SESSIONS_UI = [
  {
    label: 'Jornada', key: 'name', valueType: 'string',
  },
  {
    label: 'Fecha inicio', key: 'startDate', valueType: 'string',
  },
  {
    label: 'Fecha fin', key: 'endDate', valueType: 'string',
  },
  {
    label: 'Fecha entrega', key: 'resultsDate', valueType: 'string',
  },
  {
    label: 'Activar/Desactivar', key: 'isSessionActive', valueType: 'other',
  },
  {
    label: 'Resultados', key: 'results', valueType: 'other',
  },
  {
    label: 'Ver alumnos', key: 'seeStudents', valueType: 'other',
  },
];

type InstitutionSession = {
  id: number | string,
  institutionId: number,
  name: string,
  startDate: Date,
  endDate: Date,
  resultsDate: Date,
  active: boolean
};

type InstitutionSessionTest = {
  id: number,
  testId: number,
  institutionSessionId: number,

};

export {
  INSTITUTIONS_SESSIONS_MODAL_UI,
  INSTITUTIONS_SESSIONS_UI,
  INSTITUTIONS_ADMIN_SESSIONS_UI,
  InstitutionSession,
  InstitutionSessionTest,
};
