/* eslint-disable no-nested-ternary */
import {
  Autocomplete, Box, Button, InputAdornment, MenuItem, TextField,
} from '@mui/material';
import { DateInput } from 'semantic-ui-calendar-react';
import 'semantic-ui-css/semantic.min.css';
import React, { ReactNode, SyntheticEvent } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { getKeyTypeValidation } from '../../utils/validator';
import { formatDateToString } from '../../utils/helpers';

type CustomTextFieldProps = {
  keyField: string;
  name: string;
  value: string | Date;
  onChangeFunction?: (e: React.ChangeEvent<HTMLInputElement> | Date, source: string) => void;
  valueToRetrieveFromSelect?: string;
  disabled?: boolean;
  className?: string;
  inputMessage?: string;
};

type CustomNumericTextFieldProps = CustomTextFieldProps & {
  isCcy?: boolean;
};

type CustomSelectTextFieldProps = CustomTextFieldProps & {
  selectFields: Array<{ id: string; name: string;[key: string]: string; }>;
  className?: string;
  renderValue?: ((value: unknown) => ReactNode);
};

type CustomAutoCompleteTextFieldProps = CustomTextFieldProps & {
  size?: 'small' | 'medium' | undefined,
  selectFields: Array<{ id: string; name: string;[key: string]: string; }>;
  onChangeFunction: (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string, value: string | object | null,
  ) => void;
};

type CustomFileFieldProps = {
  fileType?: string;
  name: string,
  value?: File,
  keyField: string,
  onChangeFunction: (file: File, keyField: string) => void,
  labelClass: string,
};

function CustomAutocompleteTextField({
  disabled,
  keyField,
  name,
  value = '',
  onChangeFunction,
  selectFields,
  valueToRetrieveFromSelect,
  size,
}: CustomAutoCompleteTextFieldProps) {
  let auxValue: any = value;
  if (typeof value === 'object') {
    auxValue = valueToRetrieveFromSelect
      ? auxValue[valueToRetrieveFromSelect as keyof typeof auxValue]
      : auxValue.name;
  }

  const selectedValue = selectFields
    .find((option) => (valueToRetrieveFromSelect
      ? option[valueToRetrieveFromSelect] === auxValue
      : option.name === auxValue));

  return (
    <Autocomplete
      size={size}
      disabled={disabled}
      key={`${name}-${keyField}`}
      options={selectFields}
      getOptionLabel={(option) => option.name}
      fullWidth
      value={selectedValue || null}
      onChange={(e: SyntheticEvent<Element, Event>, v) => {
        onChangeFunction(e, keyField, v);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={name}
          variant="outlined"
          color="secondary"
        />
      )}
    />
  );
}

function CustomSelectTextField({
  disabled,
  keyField,
  name,
  value,
  onChangeFunction,
  selectFields,
  valueToRetrieveFromSelect,
  renderValue,
  className,
}: CustomSelectTextFieldProps) {
  return (
    <TextField
      disabled={disabled}
      key={`${name}-${keyField}`}
      name={name}
      select
      fullWidth
      variant="outlined"
      color='secondary'
      label={name}
      value={value}
      className={className}
      SelectProps={{
        renderValue,
      }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChangeFunction !== undefined) { onChangeFunction(e, keyField); }
      }}
    >
      {selectFields.map((option) => (
        <MenuItem
          key={option.id}
          value={valueToRetrieveFromSelect
            ? option[valueToRetrieveFromSelect]
            : option.name}>
          {option.name}
        </MenuItem>))}
    </TextField>
  );
}

function CustomTextField({
  keyField,
  name,
  value,
  onChangeFunction,
  disabled,
  className,
  inputMessage,
}: CustomTextFieldProps) {
  const { error, message } = getKeyTypeValidation(keyField, value);
  return (
    <TextField
      error={error}
      style={{ position: 'relative' }}
      helperText={
        <span
          style={{
            position: 'absolute',
            left: '0px',
            color: inputMessage ? '#E94745' : '',
            fontSize: '11px',
          }}
        >
          {message || inputMessage}
        </span>
      }
      disabled={disabled}
      key={keyField}
      fullWidth
      label={name}
      variant="outlined"
      value={value}
      className={className}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChangeFunction !== undefined) { onChangeFunction(e, keyField); }
      }}
    />
  );
}

function CustomDateTextField({
  keyField,
  name,
  value,
  onChangeFunction,
  disabled,
}: CustomTextFieldProps) {
  return (
    <Box
      sx={{
        width: '100%',
        '& .ui.input': {
          width: '100%',
        },
        '& .ui.input>input': {
          background: 'transparent',
          color: '#2c82b3',
          borderColor: 'rgba(0, 0, 0, 0.23)',
          padding: '16.5px 14px',
        },
        '& .ui.input>input:focus': {
          background: 'transparent',
          color: '#2c82b3',
          borderColor: '#2c82b3',
          borderWidth: '2px',
          padding: '16.5px 14px',
        },
      }}
    >
      <DateInput
        name={name}
        placeholder={name}
        value={formatDateToString(value)}
        onChange={(event, { value: newValue }) => {
          if (newValue) {
            const [day, month, year] = newValue.split('-').map(Number);
            const parsedDate = new Date(year, month - 1, day);

            if (onChangeFunction !== undefined) {
              onChangeFunction(new Date(parsedDate), keyField);
            }
          }
        }}
        disabled={disabled}
      />
    </Box>
  );
}

function CustomFileField({
  keyField,
  name,
  value,
  onChangeFunction,
  labelClass,
}: CustomFileFieldProps) {
  const truncateFileName = (fileName: string) => (fileName.length > 35 ? `${fileName.substring(0, 32)}...` : fileName);

  return (
    <label htmlFor={`${keyField}-upload`} className={labelClass}>
      <Button
        key={keyField}
        fullWidth
        variant="outlined"
        component="span"
        startIcon={<CloudUploadIcon />}
      >
        {value ? truncateFileName(value.name) : name}
      </Button>
      <input
        type="file"
        id={`${keyField}-upload`}
        hidden
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.files) {
            onChangeFunction(e.target.files[0], keyField);
          }
        }}
      />
    </label>
  );
}

function CustomNumericTextField({
  keyField,
  name,
  value = '0',
  onChangeFunction,
  disabled,
  isCcy,
}: CustomNumericTextFieldProps) {
  return (
    <TextField
      disabled={disabled}
      key={keyField}
      fullWidth
      label={name}
      variant="outlined"
      value={value}
      InputProps={isCcy ? {
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      } : undefined}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChangeFunction !== undefined) {
          const event = e as React.ChangeEvent<HTMLInputElement>;
          let { value: valueToInsert } = event.target;
          if (valueToInsert === '' && (event.nativeEvent as InputEvent).inputType === 'deleteContentBackward') {
            valueToInsert = '0';
          }

          const regexPattern = /^[0-9]+\.?([0-9]{1,2})?$/;
          if (!regexPattern.test(valueToInsert)) {
            return;
          }

          e.target.value = `${Number(valueToInsert)}`;
          onChangeFunction(e, keyField);
        }
      }}
    />
  );
}

export {
  CustomSelectTextField,
  CustomTextField,
  CustomDateTextField,
  CustomFileField,
  CustomAutocompleteTextField,
  CustomNumericTextField,
};
