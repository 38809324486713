import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableRow,
  IconButton,
  TextField,
  MenuItem,
  Autocomplete,
} from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import handleApiResponse from '../../../../utils/handleApiResponse';
import { getTests } from '../../../../requests/api/institutions';

type SubjectByTest = {
  [key: string]: { subjectName: string, testName: string },
};

const CustomSessionComponent = ({ testsInDB, setModalValues }: {
  testsInDB: number[],
  setModalValues: React.Dispatch<React.SetStateAction<{
    institutionId: string,
    institutionName: string,
    name: string,
    startDate: Date,
    endDate: Date,
    resultsDate: Date,
    active: boolean,
    institutionSessionTests: number[],
  }>>,
}) => {
  const [testsInSesssion, setTestsInSesssion] = useState<{
    testId: string | number,
    subjectName: string
  }[]>([]);
  const [allAvailableTests, setAllAvailableTests] = useState<{
    [key: string]: { id: number, name: string }[],
  }>({});
  const [subjectsByTest, setSubjectsByTest] = useState<SubjectByTest>({});
  const [specificTest, setSpecificTest] = useState({
    isBeingModified: false,
    subjectName: '',
    testId: '',
    testName: '',
  });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getInitialInformation = async () => {
      let localSubjectByTest: SubjectByTest;
      try {
        const { testsBySubject, subjectByTest } = await getTests();
        localSubjectByTest = subjectByTest;
        setAllAvailableTests(testsBySubject);
        setSubjectsByTest(subjectByTest);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }

      try {
        const auxTestsInDB = testsInDB.map((elem) => ({
          testId: elem,
          subjectName: localSubjectByTest[elem].subjectName,
        }));
        setTestsInSesssion(auxTestsInDB);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };
    getInitialInformation();
  }, []);

  const handleValuesChange = (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event>,
    source: string,
    value?: any,
  ) => {
    if (source === 'subjectName') {
      setSpecificTest((prevState) => ({
        ...prevState, subjectName: (e as React.ChangeEvent<HTMLInputElement>).target.value, testId: '', testName: '',
      }));
    } else {
      setSpecificTest((prevState) => ({
        ...prevState, testId: value.value,
      }));
      const arrayOfTestIds = [...testsInSesssion.map((elem) => elem.testId), value.value];
      setModalValues((prevState) => ({
        ...prevState,
        institutionSessionTests: [...new Set(arrayOfTestIds)],
      }));
    }
  };

  const handleAddingNewTests = () => {
    if (Object.keys(allAvailableTests).length === 0) {
      enqueueSnackbar('No hay ensayos con enunciado y solucionario para ser elegidos.', { variant: 'error' });
      return;
    }
    if (specificTest.isBeingModified && specificTest.testId) {
      setTestsInSesssion((prevState) => [
        ...prevState, { subjectName: specificTest.subjectName, testId: specificTest.testId },
      ]);
      setSpecificTest({
        subjectName: '',
        testId: '',
        testName: '',
        isBeingModified: true,
      });
    } else {
      setSpecificTest((prevState) => ({ ...prevState, isBeingModified: true }));
    }
  };

  const removeCurrentTest = (testId: string) => {
    const testsToRemain = testsInSesssion.filter((elem) => `${elem.testId}` !== `${testId}`);
    setTestsInSesssion(testsToRemain);
    setModalValues((prevState) => ({
      ...prevState,
      institutionSessionTests: [...testsToRemain.map((elem) => elem.testId as number)],
    }));
  };

  const removeNewTest = () => {
    setSpecificTest({
      isBeingModified: false,
      subjectName: '',
      testId: '',
      testName: '',
    });
  };

  const renderMenuItems = (relTest: {
    testId: string | number,
    subjectName: string
  }[]) => {
    if (relTest.length > 0) {
      return [{
        label: subjectsByTest[relTest[0].testId].testName,
        value: relTest[0].testId,
      }];
    }
    if (specificTest.subjectName) {
      return allAvailableTests[specificTest.subjectName].map((option) => ({
        label: option.name,
        value: option.id,
      }));
    }
    return [];
  };

  const testsUI = ({
    subjectName, testId,
  }: {
    testId: string | number,
    subjectName: string
  }) => {
    let deleteFunction;
    const relTest = testsInSesssion.filter((elem) => elem.testId === testId);
    if (relTest.length > 0) {
      deleteFunction = () => removeCurrentTest(testId as string);
    } else {
      deleteFunction = () => removeNewTest();
    }

    const menuItems = renderMenuItems(relTest);

    return (
      <TableRow key={`row-item-content-${testId}`}>
        <TableCell>
          <TextField
            key={`subject-${testId}`}
            select
            fullWidth
            name="Asignatura"
            placeholder='Selecciona una asignatura'
            disabled={relTest.length > 0}
            value={subjectName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleValuesChange(e, 'subjectName')}
          >
            {Object.keys(allAvailableTests).map((option) => (
              <MenuItem
                key={option}
                value={option}
              >
                {option}
              </MenuItem>
            ))}
          </TextField>
        </TableCell>
        <TableCell>
          <Autocomplete
            options={menuItems}
            fullWidth
            value={menuItems.find((option) => option.value === testId) || null}
            getOptionLabel={(option) => option.label}
            onChange={(e: SyntheticEvent<Element, Event>, value) => handleValuesChange(e, 'testId', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Ensayo"
                variant="outlined"
                color="secondary"
                placeholder="Ingresa un nombre"
              />
            )}
            sx={testId === 'Otro' ? { marginBottom: '5px !important' } : {}}
            disabled={relTest.length > 0}
          />
        </TableCell>
        <TableCell>
          <IconButton onClick={deleteFunction}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box>
      <Typography variant='h4' fontWeight='bold' mt={2} mb={3}>Ensayos</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Asignatura</TableCell>
            <TableCell>Nombre Ensayo</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {testsInSesssion.length > 0
            && testsInSesssion
              .map((elem) => testsUI({ subjectName: elem.subjectName, testId: elem.testId }))
          }
          {specificTest.isBeingModified && testsUI({
            subjectName: specificTest.subjectName,
            testId: specificTest.testId,
          })}
          <TableRow>
            <TableCell colSpan={3}>
              <IconButton onClick={handleAddingNewTests}>
                <AddIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default CustomSessionComponent;
