export const INSTITUTION_SESSION_TESTS_UI = [
  {
    label: 'Nombre', key: 'testName', typeField: 'text', valueType: 'string',
  },
  {
    label: 'Puntaje', key: 'score', typeField: 'text', valueType: 'string',
  },
  {
    label: 'Estado', key: 'status', typeField: 'text', valueType: 'string',
  },
  {
    label: '', key: 'action', valueType: 'other',
  },
];
